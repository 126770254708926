import React from 'react';
import styled from 'styled-components/macro';
import { color } from '../theme';
import Translate from '../components/Translate.jsx';

export const LoadingWrapper = styled.div`
  min-height: calc(100vh - 100px);
  width: 100%;
  font-size: 30px;
  padding: 40px;

  color: ${color(null, 1)};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ({ ...props }) => (
  <LoadingWrapper {...props}>
    <Translate>Loading...</Translate>
  </LoadingWrapper>
);

import React, { useCallback } from 'react';
import { observer, inject } from 'mobx-react';

import { useStore } from '../state/utils';
import translations from '../translations';

const missing = {};
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  global.printMissing = () => console.log(JSON.stringify(missing, '', ' '));
}

export const translate = (string, lang) => {
  if (translations[string] && translations[string][lang]) {
    return translations[string][lang];
  }
  if (process.env.NODE_ENV !== 'production') {
    missing[string] = { en: string, fr: '' };
  }
  return string;
};

const Translate = inject('GlobalUI')(
  observer(({ children, GlobalUI }) => translate(children, GlobalUI.lang))
);

export const useTranslator = () => {
  const { GlobalUI } = useStore('GlobalUI');

  return useCallback(
    txt => {
      return translate(txt, GlobalUI.lang);
    },
    [GlobalUI.lang]
  );
};

export const TranslateSelectValue = ({ children, instancePrefix, ...props }) => (
  <div className="Select-value" {...props}>
    <span className="Select-value-label">
      <Translate>{children}</Translate>
    </span>
  </div>
);

export class TranslateSelectOption extends React.Component {
  constructor(props) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  onFocus(event) {
    if (!this.props.isFocused) {
      this.props.onFocus(this.props.option, event);
    }
  }

  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  handleMouseEnter(event) {
    this.onFocus(event);
  }

  handleMouseMove(event) {
    this.onFocus(event);
  }

  handleTouchEnd(event) {
    // Check if the view is being dragged, In this case
    // we don't want to fire the click event (because the user only wants to scroll)
    if (this.dragging) return;

    this.handleMouseDown(event);
  }

  handleTouchMove() {
    // Set a flag that the view is being dragged
    this.dragging = true;
  }

  handleTouchStart() {
    // Set a flag that the view is not being dragged
    this.dragging = false;
  }

  render() {
    const { option, instancePrefix, optionIndex, className } = this.props;
    /* eslint-disable jsx-a11y/interactive-supports-focus  */
    /* eslint-disable jsx-a11y/role-has-required-aria-props  */
    return (
      <div
        className={className}
        style={option.style}
        role="option"
        aria-label={option.label}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        id={`${instancePrefix}-option-${optionIndex}`}
        title={option.title}
      >
        <Translate>{this.props.children}</Translate>
      </div>
    );
  }
}

export default Translate;

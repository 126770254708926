import axios from 'axios';
import { min } from 'itertools';
import { isAfter } from 'date-fns';

import { action, runInAction, flow, extendObservable } from 'mobx';

import { publicationsHistory, publicationNotification, notifyStaff } from './backend-interactions';
import createAttributionsStore from './stores/attributions';

class Cache {
  constructor(maxSize = 5) {
    this.cached = new Map();
    this.cachedOrder = new Map();
    this.orderVal = 0;

    this.maxSize = maxSize;

    this.lock = Promise.resolve();
  }

  fetch(id, onMiss) {
    if (this.cached.has(id)) {
      this.orderVal += 1;
      this.cachedOrder.set(id, this.orderVal);
      return Promise.resolve(this.cached.get(id));
    }

    this.lock = this.lock.then(onMiss).then(newValue => {
      this.orderVal += 1;
      this.cachedOrder.set(id, this.orderVal);
      this.cached.set(id, newValue);

      while (this.cachedOrder.size > this.maxSize) {
        const [idToDel] = min(this.cachedOrder, ([, order]) => order);
        this.cached.delete(idToDel);
        this.cachedOrder.delete(idToDel);
      }

      return newValue;
    });
    return this.lock;
  }
}

export default class Publications {
  constructor(projectId, backendInteractions) {
    extendObservable(
      this,
      {
        projectId,

        backendInteractions,

        latestVersion: null,
        latestPublication: null,
        latestNotification: null,

        publicationNotFound: false,

        publicationCache: new Cache(),

        publishing: false,
        loadingPublication: false,
        loadingNotification: false,

        history: [],

        link(publicationId = 'latest') {
          let host = '';
          if (
            window.location.hostname === 'localhost' ||
            window.location.hostname === 'localhost.horair.es' ||
            window.location.hostname === '192.168.0.12' ||
            window.location.hostname === '192.168.0.26'
          ) {
            host = 'https://dev.horair.es';
          }
          return `${host}/projects/${this.projectId}/${publicationId}`;
        },

        get sourceLink() {
          return this.link();
        },

        fetchHistory: flow(function* fetchHistory() {
          try {
            const { versions } = yield publicationsHistory(this.projectId);
            this.history = versions;
          } catch (error) {
            if (error.response.status === 404) return;
            this.backendInteractions.reportError(error);
          }
        }),

        get lastPublicationIsNotified() {
          if (!this.latestPublication || !this.latestNotification) {
            return undefined;
          }

          return (
            this.latestNotification.publication_version === this.latestPublication.meta.timestamp
          );
        },

        fetchNotification: flow(function* fetchNotification() {
          this.loadingNotification = true;
          try {
            const {
              has_notification: hasNotification,
              ...notification
            } = yield publicationNotification(this.projectId);
            this.loadingNotification = false;
            if (hasNotification) {
              this.latestNotification = notification;
            }
          } catch (error) {
            this.loadingNotification = false;
            this.backendInteractions.reportError(error);
          }
        }),

        notifyStaff: flow(function* notifyStaffFn(targets, lang, comment = '') {
          this.loadingNotification = true;
          try {
            const { has_notification: hasNotification, ...notification } = yield notifyStaff(
              this.projectId,
              targets,
              lang,
              comment
            );
            this.loadingNotification = false;
            if (hasNotification) {
              this.latestNotification = notification;
            }
          } catch (error) {
            this.loadingNotification = false;
            this.backendInteractions.reportError(error);
          }
        }),

        rawFetchPublication(publicationId) {
          return axios
            .get(this.link(publicationId))
            .then(result => result.data)
            .catch(error => {
              if (error.response.status === 404) return;
              this.backendInteractions.reportError(error);
            });
        },

        fetchPublication(publicationId = 'latest') {
          if (publicationId === 'latest') {
            return this.rawFetchPublication().then(publication => {
              runInAction(() => {
                this.latestPublication = publication;
              });
              return publication;
            });
          }
          return this.publicationCache.fetch(publicationId, () =>
            this.rawFetchPublication(publicationId)
          );
        },

        fetchLatestPublicationDate: flow(function* fetchLatestPublicationDate(forceVersion) {
          this.loadingPublication = true;
          try {
            const result = yield axios.head(this.link(forceVersion));

            this.publicationNotFound = false;
            this.loadingPublication = false;
            this.latestVersion = new Date(result.headers['last-modified']);
            const now = new Date();
            if (isAfter(this.latestVersion, now)) {
              this.latestVersion = now;
            }
          } catch (e) {
            this.loadingPublication = false;
            this.publicationNotFound = true;
          }
        }),

        get lastestPublicationAttributionStore() {
          if (!this.latestPublication) return null;
          const attributionsStore = createAttributionsStore();
          attributionsStore.load(this.latestPublication.attributions);
          return attributionsStore;
        },

        publish(lastDayToPublish) {
          this.publishing = true;
          backendInteractions.publishProject(lastDayToPublish).then(version => {
            runInAction(() => {
              this.publishing = false;
            });
            return Promise.all([
              this.fetchLatestPublicationDate(version),
              this.rawFetchPublication(version).then(publication => {
                runInAction(() => {
                  this.latestPublication = publication;
                });
              }),
            ]);
          });
        },
      },
      {
        fetchPublication: action.bound,
        fetchLatestPublicationDate: action.bound,
        publish: action.bound,
      }
    );
  }

  shareLink(staffId) {
    const base = `${window.location.origin}/view/${this.projectId}`;
    if (staffId) return `${base}/${staffId}/individual_schedule`;
    return `${base}/all/individual_calendar`;
  }

  calendarLink(staffId, lang = 'en', protocol = 'https') {
    const isProd = window.location.hostname === 'app.horair.es';
    const host = `${isProd ? 'prod' : 'dev'}.api.horair.es`;

    return `${protocol}://${host}/calendar/${this.projectId}/${staffId}/calendar.ics?lang=${lang}`;
  }
}

/* eslint-disable camelcase */
import axios from 'axios';

import { v4 as uuid } from 'uuid';

import { isObservable, toJS } from 'mobx';
import values from 'lodash/values';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import PQueue from '../vendor/p-queue';

const isProd = window.location.hostname === 'app.horair.es';

const service = axios.create({
  baseURL: `https://${isProd ? 'prod' : 'dev'}.api.horair.es`,
  withCredentials: true,
});

const queryContext = ui => {
  ui.startQuerying();

  return promise =>
    promise
      .then(() => {
        ui.logInSuccess();
      })
      .catch(err => {
        ui.logInFailure();
        if (err.response && err.response.status !== 403) {
          throw err;
        }
      });
};

const mergeProject = (allProjects, project) => {
  const hasInfo = !!project.info;

  allProjects.merge(project.id, {
    dbName: project.id,
    name: (hasInfo && project.info.name) || project.name,
    isAdmin: project.is_admin,
    onServer: true,

    startMonth: hasInfo && project.info.startMonth,
    endMonth: hasInfo && project.info.endMonth,
  });
};

export const checkLogin = token => service.get(`login/${token}`);

export const queryProjects = (ui, token) => {
  let query = Promise.resolve();
  if (token) {
    query = checkLogin(token).catch(() => null);
  }
  return queryContext(ui)(
    query.then(() =>
      service.get('projects/').then(results => {
        if (!results.data || !results.data.projects) return ui.initialisation;

        return ui.initialisation.then(() => {
          results.data.projects.forEach(p => mergeProject(ui.allProjects, p));
        });
      })
    )
  );
};

export const createProject = (dbName, name, ui) => {
  ui.startQuerying();
  return service
    .post('projects/', { local_name: dbName, name })
    .then(result => {
      ui.logInSuccess();
      mergeProject(ui.allProjects, result.data);
      return dbName;
    })
    .catch(err => {
      ui.stopQuerying();
      throw err;
    });
};

export const deleteProject = (dbName, ui) => {
  ui.startQuerying();
  return service
    .delete(`projects/${dbName}`)
    .then(() => {
      ui.logInSuccess();
    })
    .catch(err => {
      ui.stopQuerying();
      throw err;
    });
};

export const getProjectAfter = (projectId, after) =>
  service.get(`projects/${projectId}/after/${after}`).then(result => result.data);

export const publishProject = (projectId, lastDayToPublish = '') =>
  service
    .post(`projects/${projectId}/publish`, { last_day_attribution: lastDayToPublish })
    .then(result => result.data.version);

export const fetchPermissions = projectId =>
  service.get(`projects/${projectId}/permissions`).then(result => result.data);

export const inviteUser = (projectId, email, lang = 'en') =>
  service.post(`projects/${projectId}/invite`, { email, lang }).then(result => result.data);

export const removeUser = (projectId, email) =>
  service
    .post(`projects/${projectId}/permissions`, { [email]: 'remove' })
    .then(result => result.data);

export const requestLogin = (email, ui, lang = 'en') => {
  ui.startQuerying();
  return service
    .post(`login`, { email, lang })
    .then(() => {
      ui.stopQuerying();
    })
    .catch(err => {
      ui.stopQuerying();
      throw err;
    });
};

export const logout = ui =>
  service
    .get('logout')
    .then(() => ui.logInFailure())
    .catch(err => {
      ui.stopQuerying();
      throw err;
    });

export const login = (token, ui) => queryContext(ui)(service.get(`login/${token}`));

export const register = (email, name = '', lang = 'en') =>
  service.post(`register`, { email, name, lang }).then(res => res.data);

export const getProjectData = projectId =>
  service.get(`projects/${projectId}`).then(result => result.data);

export class BackendSavedProject {
  constructor(projectId, uiState, updateFcn) {
    this.projectId = projectId;
    this.uiState = uiState;
    this.updateFcn = updateFcn;

    this.sessionId = uuid();

    this.queue = new PQueue({ concurrency: 1 });

    this.data = getProjectData(this.projectId);
    this.latestVersion = null;
    this.latestRefresh = {};

    this.data.then(data => {
      this.latestVersion = data.latest_version || 0;
    });

    this.batchData = {};
    this.sendBatch = debounce(this.sendBatch.bind(this), 250, { maxWait: 1000 });
    this.refreshData = this.refreshData.bind(this);

    this.interval = setInterval(this.refreshData, 60000);
  }

  stop() {
    this.refreshData();
    clearInterval(this.interval);
  }

  sendBatch() {
    this.queue.add(() => {
      const currentBatch = values(this.batchData);
      this.batchData = {};

      service
        .post(`projects/${this.projectId}/store`, {
          batch: currentBatch,
          sync_session: this.sessionId,
        })
        .then(() => {
          // We bust the refresh cache
          this.latestRefresh = {};
        })
        .catch(error => {
          if (this.uiState) {
            this.uiState.reportError(error);
          }
        });
    });
  }

  refreshData() {
    if (!this.latestVersion && this.latestVersion !== 0) return;

    this.queue.add(() =>
      service
        .get(`projects/${this.projectId}/store/updates_since/${this.latestVersion}`, {
          params: { sync_session: this.sessionId },
        })
        .then(result => {
          if (result.data && result.data.changes) {
            this.latestRefresh = result.data.changes;
          }
          if (result.data && !isEmpty(result.data.changes)) {
            this.updateFcn(result.data.changes);
            this.latestVersion = result.data.latest_version;
          }
        })
        .catch(error => {
          if (this.uiState) {
            this.uiState.reportError(error);
          }
        })
    );
  }

  update(inputType, key, value) {
    let type = `${inputType}s`;
    if (inputType === 'main') {
      type = 'info';
    }

    // we make sure this is not an update from the refresh logic
    // As a deletion
    if (
      !value &&
      this.latestRefresh[type] &&
      this.latestRefresh[type].deletions &&
      this.latestRefresh[type].deletions.includes(key)
    ) {
      return;
    }

    // As an update
    if (
      this.latestRefresh[type] &&
      this.latestRefresh[type].updates &&
      this.latestRefresh[type].updates[key] &&
      isEqual(this.latestRefresh[type].updates[key], value)
    ) {
      return;
    }

    this.batchData[`${type}-${key}`] = { type, key, data: value };
    this.sendBatch();
  }

  onChangeHandler(type, change) {
    if (change.type === 'delete') {
      this.update(type, change.name);
    } else {
      let value = change.newValue || change.value;
      if (isObservable(value)) {
        value = toJS(value);
      }
      const key = change.name;

      this.update(type, key, value);
    }
  }

  fetchValues(type) {
    return this.data.then(({ [`${type}s`]: doc }) => doc.map(({ key, value }) => [key, value]));
  }

  fetchValue(type, key) {
    return this.data.then(({ [key]: value }) => [key, value]);
  }
}

export const publicationsHistory = projectId =>
  service.get(`projects/${projectId}/history`).then(result => result.data);

export const publicationNotification = projectId =>
  service.get(`projects/${projectId}/publication_notification`).then(result => result.data);

export const notifyStaff = (projectId, targets, lang = 'en', comment = '') =>
  service
    .post(`projects/${projectId}/publication_notification`, { targets, lang, comment })
    .then(result => result.data);

export const allAdminProjects = () => service.get('admin/projects').then(result => result.data);
export const allAdminUsers = () => service.get('admin/users').then(result => result.data);
export const adminUserInfo = user => service.get(`admin/users/${user}`).then(result => result.data);
export const changeUnitsPaid = (projectId, newUnits) =>
  service.post(`admin/projects/${projectId}`, { units_paid: newUnits }).then(result => result.data);

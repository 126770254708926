import { toJS } from 'mobx';
import fromPairs from 'lodash/fromPairs';

import { Field, Model } from './helper';

export default new Model({
  description: new Field(),
  shorthand: new Field(),
  startTime: new Field(),
  endTime: new Field(),
  slots: new Field(),
  color: new Field(),
  timeFilter: new Field(),
  duration: new Field({
    extract: toJS,
    update: (value, previousValue) => {
      // eslint-disable-next-line no-param-reassign
      previousValue.hours = value.hours;
      return previousValue;
    },
  }),
  categories: new Field({
    extract: value => fromPairs(value.toJSON()),
    update: (categories, previousCategories) => {
      previousCategories.replace(categories);
      return previousCategories;
    },
  }),
});

import { parse as parseFP, format as formatFP, addDays, subDays } from 'date-fns/fp';
import {
  format as rawFormat,
  formatDistance,
  addMonths,
  getUnixTime,
  eachDayOfInterval,
  eachWeekOfInterval,
  startOfISOWeek,
  endOfISOWeek,
  startOfMonth,
  endOfMonth,
  isBefore,
  isValid,
  isWeekend,
} from 'date-fns';
import { fr, enGB } from 'date-fns/locale';

import memoize from 'lodash/memoize';
import flow from 'lodash/fp/flow';

import rawIsHoliday from '../quarolib/helpers/holidays/isHoliday';
import holidaysCH from '../quarolib/helpers/holidays/locale/ch';
import holidaysFR from '../quarolib/helpers/holidays/locale/fr';

const BASE_DATE = new Date('2017-01-01T12:00:00Z');

export const DAY_FORMAT = 'yyyy-MM-dd';
export const parseDay = parseFP(BASE_DATE, DAY_FORMAT);
export const formatDay = formatFP(DAY_FORMAT);
export const WEEK_FORMAT = 'RRRR_II';
export const parseWeek = parseFP(BASE_DATE, WEEK_FORMAT);
export const formatWeek = formatFP(WEEK_FORMAT);
export const MONTH_FORMAT = 'yyyy-MM';
export const parseMonth = parseFP(BASE_DATE, MONTH_FORMAT);
export const formatMonth = formatFP(MONTH_FORMAT);

export const setLocale = locale => {
  if (locale === 'fr') {
    global.__dateLocale__ = fr;
  } else {
    global.__dateLocale__ = enGB;
  }
};

export const getLocale = () => global.__dateLocale__ || enGB;

export const setHolidayRegion = (country, region) => {
  if (country === 'fr') {
    global.__holidayRegion__ = holidaysFR;
  } else if (country.toLowerCase() === 'switzerland') {
    global.__holidayRegion__ = holidaysCH[region.toLowerCase()];
  } else {
    global.__holidayRegion__ = holidaysCH.vd;
  }
};

export const getHolidayRegion = () => global.__holidayRegion__ || holidaysCH.vd;

export const format = (date, formatString) => {
  try {
    return rawFormat(date, formatString, { locale: getLocale() });
  } catch (e) {
    return '---';
  }
};

export const fromNow = date =>
  formatDistance(date, new Date(), {
    addSuffix: true,
    locale: getLocale(),
  });

export const dayToWeek = memoize(
  flow(
    parseDay,
    formatWeek
  )
);

export const dayToMonth = memoize(
  flow(
    parseDay,
    formatMonth
  )
);

export const dayInMonth = (day, month) => dayToMonth(day) === month;

export const nextDay = memoize(
  flow(
    parseDay,
    addDays(1),
    formatDay
  )
);

export const previousDay = memoize(
  flow(
    parseDay,
    subDays(1),
    formatDay
  )
);

export const buildYearMonthObject = date => ({
  year: date.getFullYear(),
  month: date.getMonth(),
});

export const buildMonthDate = ({ month, year }) => new Date(year, month, 1);

export const buildAllWeeks = (startMonth, endMonth) => {
  const start = startOfMonth(buildMonthDate(startMonth));
  const end = endOfMonth(buildMonthDate(endMonth));

  return eachWeekOfInterval({ start, end }, { weekStartsOn: 1 }).map(formatWeek);
};

export const buildAllMonths = (startMonth, endMonth) => {
  const start = startOfMonth(buildMonthDate(startMonth));
  const end = startOfMonth(buildMonthDate(endMonth));

  const allMonths = [start];

  const latestMonth = () => allMonths.slice(-1)[0];

  while (isBefore(latestMonth(), end)) {
    allMonths.push(addMonths(latestMonth(), 1));
  }

  return allMonths;
};

export const daysOfWeek = memoize(week => {
  const start = flow(
    parseWeek,
    startOfISOWeek
  )(week);
  const end = endOfISOWeek(start);
  return eachDayOfInterval({ start, end }).map(formatDay);
});

export const daysOfMonth = memoize(month => {
  const start = flow(
    parseMonth,
    startOfMonth
  )(month);
  const end = endOfMonth(start);
  return eachDayOfInterval({ start, end }).map(formatDay);
});

export const weeksOfMonth = memoize(month => {
  const startDay = flow(
    parseMonth,
    startOfMonth
  )(month);
  const end = flow(
    endOfMonth,
    endOfISOWeek
  )(startDay);
  const start = startOfISOWeek(startDay);

  return eachWeekOfInterval({ start, end }, { weekStartsOn: 1 }).map(formatWeek);
});

const parseBase = parseFP(BASE_DATE);
export const parseTime = (rawTime, deep) => {
  const time = rawTime.toUpperCase().replace(' ', '');

  let date = parseBase('H:m', time);
  if (isValid(date)) return date;

  date = parseBase('H', time);
  if (isValid(date)) return date;

  date = parseBase('h:ma', time);
  if (isValid(date)) return date;

  date = parseBase('ha', time);
  if (isValid(date)) return date;

  date = parseBase('H m', rawTime);
  if (isValid(date)) return date;

  date = parseBase('Hm', time);
  if (isValid(date)) return date;

  date = parseBase('H:m:s', time);
  if (isValid(date)) return date;

  if (!deep) {
    const cleanedTime = time
      .replace(/[^0-9]/, ':')
      .replace(/:+/, ':')
      .replace(/[^0-9]$/, '')
      .replace(/^[^0-9]/, '');
    return parseTime(cleanedTime, true);
  }
  return date;
};

export const parseUnixTime = time => getUnixTime(parseTime(time));

export const isHoliday = memoize(day => {
  const date = parseDay(day);
  return isWeekend(date) || rawIsHoliday(date, { locale: getHolidayRegion() });
});

export const clearDateCaches = () => {
  dayToWeek.cache.clear();
  dayToMonth.cache.clear();
  isHoliday.cache.clear();
  daysOfWeek.cache.clear();
  daysOfMonth.cache.clear();
  weeksOfMonth.cache.clear();
};

import { observable, action, reaction, toJS } from 'mobx';
import { differenceInMonths, addMonths, endOfMonth } from 'date-fns';

import { buildMonthDate, formatDay, formatWeek, isHoliday } from '../dates';

const differentSets = (s1, s2) => {
  const set1 = new Set(s1);
  const set2 = new Set(s2);
  if (set1.size !== set2.size) return true;

  // eslint-disable-next-line no-restricted-syntax
  for (const elem of set1) {
    if (!set2.has(elem)) {
      return true;
    }
  }
  return false;
};

const extractInfo = project => ({
  name: project.name,
  standardDay: project.standardDay,
  startMonth: {
    month: project.startMonth.month,
    year: project.startMonth.year,
  },
  endMonth: {
    month: project.endMonth.month,
    year: project.endMonth.year,
  },
  holidays: {
    region: project.holidays.region,
    country: project.holidays.country,
  },
  customHolidays: [...project.customHolidays],
  customWorkdays: [...project.customWorkdays],
  previousProject: project.previousProject,
});

export default (defaultName = '') => {
  const currentYear = new Date().getUTCFullYear();

  return observable(
    {
      raw: {
        name: defaultName,
        holidays: {
          country: 'Switzerland',
          region: 'VD',
        },
        startMonth: {
          month: null,
          year: currentYear,
        },
        endMonth: {
          month: null,
          year: currentYear,
        },
        standardDay: 8.4,
        previousProject: '',
        customHolidays: new Set(),
        customWorkdays: new Set(),
      },

      init(project) {
        this.project = project;
        return this.project.fetchValue('main', 'info').then(([, value]) => {
          this.update(value);
          reaction(
            () => extractInfo(this.raw),
            newInfo => this.project.update('main', 'info', newInfo),
            { delay: 1000 }
          );
        });
      },

      load(projectInfo) {
        this.update(projectInfo);
      },

      dump() {
        return toJS(this.raw);
      },

      get name() {
        return this.raw.name;
      },

      get startMonth() {
        return this.raw.startMonth;
      },

      get startMonthAsDate() {
        return buildMonthDate(this.startMonth);
      },

      get endMonth() {
        return this.raw.endMonth;
      },

      get endMonthAsDate() {
        return endOfMonth(buildMonthDate(this.endMonth));
      },

      get firstDay() {
        return formatDay(this.startMonthAsDate);
      },

      get firstWeek() {
        return formatWeek(this.startMonthAsDate);
      },

      get lastDay() {
        return formatDay(this.endMonthAsDate);
      },

      get lastWeek() {
        return formatWeek(this.startMonthAsDate);
      },

      dayIsInPeriod(day) {
        return day >= this.firstDay && day <= this.lastDay;
      },

      isHoliday(day) {
        if (this.isCustomHoliday(day)) return true;
        if (this.isCustomWorkday(day)) return false;
        return isHoliday(day);
      },

      isCustomHoliday(day) {
        return this.raw.customHolidays.has(day);
      },

      isCustomWorkday(day) {
        return this.raw.customWorkdays.has(day);
      },

      get customHolidays() {
        return this.raw.customHolidays;
      },

      get customWorkdays() {
        return this.raw.customWorkdays;
      },

      get holidays() {
        return this.raw.holidays;
      },

      get standardDay() {
        return this.raw.standardDay;
      },

      get previousProject() {
        return this.raw.previousProject;
      },

      get duration() {
        return (
          differenceInMonths(buildMonthDate(this.endMonth), buildMonthDate(this.startMonth)) + 1
        );
      },

      updates(changes) {
        if (!changes.updates || !changes.updates.info) return;
        this.update(changes.updates.info);
      },

      update({
        name,
        startMonth,
        endMonth,
        holidays,
        standardDay,
        duration,
        previousProject,
        customHolidays,
        customWorkdays,
      }) {
        if (name) this.raw.name = name;
        const previousDuration = this.duration;
        if (startMonth) {
          const { month = this.raw.startMonth.month, year = this.raw.startMonth.year } = startMonth;
          this.raw.startMonth.month = month;
          this.raw.startMonth.year = year;

          if (!duration && !endMonth) {
            // eslint-disable-next-line
            duration = previousDuration;
          }
        }

        // eslint-disable-next-line
        if (duration === '') duration = 0;

        if (duration !== undefined && !endMonth) {
          let correctedDuration = parseInt(duration, 10);
          if (correctedDuration < 0) correctedDuration = 0;
          if (correctedDuration > 12) correctedDuration = 12;
          const newMonth = addMonths(
            new Date(this.startMonth.year, this.startMonth.month, 15),
            correctedDuration - 1
          );
          this.raw.endMonth.month = newMonth.getMonth();
          this.raw.endMonth.year = newMonth.getFullYear();
        }

        if (endMonth) {
          const { month = this.raw.endMonth.month, year = this.raw.endMonth.year } = endMonth;
          this.raw.endMonth.month = month;
          this.raw.endMonth.year = year;
        }
        if (holidays) {
          const {
            region = this.raw.holidays.region,
            country = this.raw.holidays.country,
          } = holidays;
          this.raw.holidays.region = region;
          this.raw.holidays.country = country;
        }
        if (standardDay) this.raw.standardDay = standardDay;
        if (previousProject || previousProject === '') this.raw.previousProject = previousProject;

        if (customHolidays && differentSets(customHolidays, this.raw.customHolidays)) {
          this.raw.customHolidays.clear();
          customHolidays.forEach(d => this.raw.customHolidays.add(d));
        }

        if (customWorkdays && differentSets(customWorkdays, this.raw.customWorkdays)) {
          this.raw.customWorkdays.clear();
          customWorkdays.forEach(d => this.raw.customWorkdays.add(d));
        }
      },
    },
    {
      init: action.bound,
      load: action.bound,
      update: action.bound,
    }
  );
};

import { observable, observe, action, toJS } from 'mobx';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';

const deepUpdate = (root, outerId, middleId, innerId) => {
  if (!root.has(outerId)) {
    root.set(outerId, observable.map());
  }
  const middleInfo = root.get(outerId);
  if (!middleInfo.has(middleId)) {
    middleInfo.set(middleId, observable.map());
  }
  middleInfo.get(middleId).set(innerId, true);
};

export default () =>
  observable(
    {
      raw: observable.map(),

      shiftDayStaff: observable.map(),
      staffDayShift: observable.map(),
      staffShiftDay: observable.map(),

      init(project) {
        this.project = project;

        return project.fetchValues('attribution').then(keyValues =>
          keyValues.forEach(([key, value]) => {
            if (!isEmpty(value)) this.set(...value);
            // eslint-disable-next-line no-console
            else console.log('unexpected empy attribution', key);
          })
        );
      },

      startObserve() {
        const onChange = this.project.onChangeHandler.bind(this.project, 'attribution');
        this._observer = observe(this.raw, onChange);
      },

      load(attributions) {
        attributions
          .filter(({ value }) => !isEmpty(value))
          .forEach(({ value }) => this.set(...value));
      },

      dump() {
        return [...this.raw.entries()].map(([key, value]) => ({ key, value: toJS(value) }));
      },

      set(day, staffId, shiftId) {
        const shiftAttribution = [day, staffId, shiftId];
        const attributionId = JSON.stringify(shiftAttribution);
        if (!attributionId || !shiftId || !day || !staffId) {
          // eslint-disable-next-line no-console
          console.log('att error', { attributionId, shiftId, day, staffId });
          return;
        }

        if (this.raw.has(attributionId)) return;
        this.raw.set(attributionId, shiftAttribution);

        deepUpdate(this.shiftDayStaff, shiftId, day, staffId);
        deepUpdate(this.staffDayShift, staffId, day, shiftId);
        deepUpdate(this.staffShiftDay, staffId, shiftId, day);
      },

      delete(day, staffId, shiftId) {
        const shiftAttribution = [day, staffId, shiftId];
        const attributionId = JSON.stringify(shiftAttribution);

        if (!this.raw.has(attributionId)) return;

        this.raw.delete(attributionId);
        this.shiftDayStaff
          .get(shiftId)
          .get(day)
          .delete(staffId);
        this.staffDayShift
          .get(staffId)
          .get(day)
          .delete(shiftId);
        this.staffShiftDay
          .get(staffId)
          .get(shiftId)
          .delete(day);
      },

      deleteAttribution(day, staffId, shiftId) {
        this.delete(day, staffId, shiftId);
      },

      toggle(staffId, day, shiftId) {
        const shiftAttribution = [day, staffId, shiftId];
        const attributionId = JSON.stringify(shiftAttribution);

        if (this.raw.has(attributionId)) {
          this.delete(day, staffId, shiftId);
        } else {
          this.set(day, staffId, shiftId);
        }
      },

      exists(staffId, day, shiftId) {
        const attributionId = JSON.stringify([day, staffId, shiftId]);
        return this.raw.has(attributionId);
      },

      updates(changes) {
        if (changes.deletions) {
          changes.deletions.forEach(id => {
            const parsed = JSON.parse(id);
            this.delete(...parsed);
          });
        }

        if (changes.updates) {
          values(changes.updates)
            .filter(update => !isEmpty(update))
            .forEach(update => {
              this.set(...update);
            });
        }
      },

      get isEmpty() {
        return !this.raw.size;
      },
    },
    {
      init: action.bound,
      load: action.bound,
      set: action.bound,
      delete: action.bound,
      deleteAttribution: action.bound,
      toggle: action.bound,
      updates: action.bound,
    }
  );

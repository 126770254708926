import React from 'react';
import styled from 'styled-components/macro';
import { ellipsis } from 'polished';

import { observer } from 'mobx-react';

import { injectInProject as inject } from '../state/utils';

import WhenCurrentProject from './WhenCurrentProject.jsx';

import Navigation, {
  MultipleNavigation,
  NavigationLink,
  NavigationElement,
} from '../components/Navigation.jsx';
import Translate from '../components/Translate.jsx';

const projectScreens = ['edit_staff', 'edit_shift', 'project'];
const editScreens = ['inspect_staff', 'inspect_shift', 'grid'];
const summaryScreens = ['summary', 'shifts_summary', 'absences_summary', 'staff_shifts_summary'];

export const EditorNav = observer(() => (
  <>
    <WhenCurrentProject>
      <MultipleNavigation screens={projectScreens} default="project">
        <Translate>Project</Translate>
      </MultipleNavigation>
      <Navigation to="absences">
        <Translate>Absences</Translate>
      </Navigation>
      <MultipleNavigation screens={editScreens} default="grid">
        <Translate>Edit</Translate>
      </MultipleNavigation>
      <MultipleNavigation screens={summaryScreens} default="summary">
        <Translate>Summary</Translate>
      </MultipleNavigation>
    </WhenCurrentProject>
  </>
));

export const DetailsEditorNav = () => (
  <WhenCurrentProject>
    <Navigation to="print">
      <Translate>Print</Translate>
    </Navigation>
    <Navigation to="publication">
      <Translate>Share</Translate>
    </Navigation>
  </WhenCurrentProject>
);

export const SettingsEditorNav = () => (
  <WhenCurrentProject>
    <Navigation to="team">
      <Translate>Permissions</Translate>
    </Navigation>
  </WhenCurrentProject>
);

export const WhenStaff = inject('GlobalUI')(
  observer(({ GlobalUI, children }) => {
    if (
      GlobalUI.currentProject &&
      GlobalUI.currentProject.UIStateStore.ready &&
      !GlobalUI.currentProject.UIStateStore.overviewMode
    ) {
      return children;
    }

    return null;
  })
);

export const WhenOverview = inject('GlobalUI')(
  observer(({ GlobalUI, children }) => {
    if (
      GlobalUI.currentProject &&
      GlobalUI.currentProject.UIStateStore.ready &&
      GlobalUI.currentProject.UIStateStore.overviewMode
    ) {
      return children;
    }

    return null;
  })
);

export const ScheduleNav = () => (
  <WhenStaff>
    <Navigation to="individual_schedule">
      <Translate>Schedule</Translate>
    </Navigation>
  </WhenStaff>
);

export const ViewerNav = () => (
  <>
    <WhenStaff>
      <Navigation to="individual_schedule">
        <Translate>Schedule</Translate>
      </Navigation>
    </WhenStaff>
    <Navigation to="individual_calendar">
      <Translate>Calendar</Translate>
    </Navigation>
    <Navigation to="overview_schedule">
      <Translate>Overview</Translate>
    </Navigation>
    <Navigation to="individual_absences">
      <Translate>Absences</Translate>
    </Navigation>
  </>
);

export const IntegrationViewerNav = () => (
  <WhenStaff>
    <Navigation to="calendar_integration">
      <Translate>Calendar Apps</Translate>
    </Navigation>
  </WhenStaff>
);

const NamedNav = styled(NavigationElement)`
  padding-bottom: 0.5em;
  font-size: 0.9em;
  cursor: default;
  ${ellipsis('100%')};
`;
export const NameViewerNav = inject('GlobalUI')(
  observer(({ GlobalUI }) => {
    const selected =
      GlobalUI.currentProject &&
      GlobalUI.currentProject.UIStateStore.ready &&
      !GlobalUI.currentProject.UIStateStore.overviewMode &&
      GlobalUI.currentProject.UIStateStore.selectedStaff;

    if (!selected)
      return (
        <NamedNav>
          <Translate>Overview</Translate>
        </NamedNav>
      );
    return <NamedNav>{selected.name}</NamedNav>;
  })
);

export const DetailsViewerNav = () => (
  <>
    <Navigation to="full_individual_calendar">
      <Translate>Full Calendar</Translate>
    </Navigation>
    <WhenStaff>
      <Navigation to="full_individual_schedule">
        <Translate>Full Schedule</Translate>
      </Navigation>
    </WhenStaff>
    <Navigation to="full_overview_schedule">
      <Translate>Full Overview</Translate>
    </Navigation>
    <Navigation to="history">
      <Translate>History</Translate>
    </Navigation>
  </>
);

const LogState = inject('GlobalUI')(
  observer(({ GlobalUI }) => {
    if (GlobalUI.loggedState === 'loggedin') {
      return <Translate>Log Out</Translate>;
    }
    return <Translate>Log In</Translate>;
  })
);

export const LanguageNav = () => (
  <Navigation to="user_params">
    <Translate>Language</Translate>
  </Navigation>
);

export const GlobalNav = () => (
  <>
    <WhenCurrentProject>
      <Navigation to="settings">
        <Translate>Change Project</Translate>
      </Navigation>
    </WhenCurrentProject>

    <Navigation to="login">
      <LogState />
    </Navigation>
  </>
);

export const AdminNav = observer(() => (
  <>
    <NavigationLink to="/admin/projects">Projects</NavigationLink>
    <NavigationLink to="/admin/users">Users</NavigationLink>
  </>
));

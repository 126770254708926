import { observable, observe, action, reaction, toJS } from 'mobx';

import entries from 'lodash/entries';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { v4 as uuid } from 'uuid';

import staffModel from '../models/staff';

export default () =>
  observable(
    {
      raw: observable.map(),
      canObserve: false,

      init(project) {
        this.project = project;
        return this.project
          .fetchValues('staff')
          .then(keyValues => keyValues.forEach(([key, value]) => this.set(key, value)));
      },

      startObserve() {
        const onChange = this.project.onChangeHandler.bind(this.project, 'staff');
        this._canObserve = true;
        this._observer = observe(this.raw, onChange);
      },

      load(staffs) {
        staffs.forEach(({ key, value }) => this.set(key, value));
      },

      import(staffs) {
        staffs.forEach(({ value }) => {
          const key = uuid();
          this.set(key, value);
        });
      },

      dump() {
        return [...this.raw.entries()].map(([key, value]) => ({ key, value: toJS(value) }));
      },

      createStaff() {
        const newId = uuid();
        this.set(newId, {
          name: '',
          shorthand: '',
          activityRate: 100,
          group: '',
          email: '',
          //eslint-disable-next-line camelcase
          additional_info: '',
          arrivalMonth: '',
          departureMonth: '',

          categories: observable.map({}),
          vacationDays: 10,
        });
        return newId;
      },

      deleteStaff(staffId) {
        this.raw.delete(staffId);
      },

      set(
        key,
        {
          email,
          name,
          shorthand,
          group,
          vacationDays = 10,
          activityRate = 100,
          categories = {},
          arrivalMonth,
          departureMonth,
          additional_info: additionalInfo,
        }
      ) {
        const staff = observable({
          id: key,
          email,
          name,
          shorthand,
          group,
          //eslint-disable-next-line camelcase
          additional_info: additionalInfo,

          arrivalMonth,
          departureMonth,

          activityRate: parseInt(activityRate, 10),
          categories: observable.map(categories),
          vacationDays: parseInt(vacationDays, 10),
        });
        if (this.project) {
          reaction(
            () => staffModel.extract(staff),
            ({ key: key_, value }) => this.project.update('staff', key_, value),
            { delay: 1000 }
          );
        }
        this.raw.set(key, staff);
      },

      get defaultStaffId() {
        if (!this.raw.size) return '';
        return this.all[0].id;
      },

      get(staffId) {
        return this.raw.get(staffId);
      },

      has(staffId) {
        return this.raw.has(staffId);
      },

      get all() {
        return sortBy([...this.raw.values()], val => [val.group, val.name]);
      },

      update(key, props) {
        if (!this.raw.has(key)) {
          this.set(key, props);
          return;
        }
        staffModel.update(this.raw.get(key), props);
      },

      updates(changes) {
        if (changes.deletions) {
          changes.deletions.forEach(id => {
            if (this.raw.has(id)) this.raw.delete(id);
          });
        }

        if (changes.updates) {
          entries(changes.updates)
            .filter(([, update]) => !isEmpty(update))
            .forEach(([key, update]) => this.update(key, update));
        }
      },
    },
    {
      init: action.bound,
      load: action.bound,
      import: action.bound,
      createStaff: action.bound,
      deleteStaff: action.bound,
      set: action.bound,
      updates: action.bound,
    }
  );

import { observable, observe, action, extendObservable } from 'mobx';

import maxBy from 'lodash/maxBy';

import { nextDay, daysOfWeek, dayToWeek, dayToMonth, dayInMonth } from '../dates';

export default class WorkedTimeView {
  constructor(staffId, views, allAttributions, info) {
    const sumWithoutHolidays = (total, [day]) => total + (info.isHoliday(day) ? 1 : 0);
    extendObservable(
      this,
      {
        views,
        info,
        allAttributions,

        total: 0,
        workHours: observable.map(),
        workHoursByWeek: observable.map(),
        workHoursByMonth: observable.map(),

        overnights: 0,
        overnightsByWeek: observable.map(),
        overnightsByMonth: observable.map(),

        observerDisposer: {},

        get maxWeek() {
          return maxBy(this.workHoursByWeek.entries(), ([, value]) => value)[0];
        },

        hoursByDay(day) {
          if (!this.workHours.has(day)) return 0;
          return this.workHours.get(day);
        },
        hoursByWeek(week) {
          if (!this.workHoursByWeek.has(week)) return 0;
          return this.workHoursByWeek.get(week);
        },

        get holidaysWorked() {
          return [...this.workHours.entries()]
            .filter(([, worked]) => worked > 0)
            .reduce(sumWithoutHolidays, 0);
        },

        holidaysWorkedInMonth(month) {
          return [...this.workHours.entries()]
            .filter(([, worked]) => worked > 0)
            .filter(([day]) => dayInMonth(day, month))
            .reduce(sumWithoutHolidays, 0);
        },
        holidaysWorkedInWeek(week) {
          return daysOfWeek(week)
            .map(day => [day, this.workHours.get(day)])
            .filter(([, worked]) => worked && worked > 0)
            .filter(([day]) => dayToWeek(day) === week)
            .reduce(sumWithoutHolidays, 0);
        },

        addWorkToDay(day, newHours) {
          if (this.info.dayIsInPeriod(day)) {
            this.total += newHours;
          }

          const plannedTime = this.workHours.has(day) ? this.workHours.get(day) : 0;
          this.workHours.set(day, newHours + plannedTime);

          const week = dayToWeek(day);
          const plannedWeekTime = this.workHoursByWeek.has(week)
            ? this.workHoursByWeek.get(week)
            : 0;
          this.workHoursByWeek.set(week, newHours + plannedWeekTime);

          const month = dayToMonth(day);
          const plannedMonthTime = this.workHoursByMonth.has(month)
            ? this.workHoursByMonth.get(month)
            : 0;
          this.workHoursByMonth.set(month, newHours + plannedMonthTime);
        },
        removeWorkToDay(day, hoursToRemove) {
          if (this.info.dayIsInPeriod(day)) {
            this.total -= hoursToRemove;
          }

          this.workHours.set(day, this.workHours.get(day) - hoursToRemove);

          const week = dayToWeek(day);
          this.workHoursByWeek.set(week, this.workHoursByWeek.get(week) - hoursToRemove);

          const month = dayToMonth(day);
          this.workHoursByMonth.set(month, this.workHoursByMonth.get(month) - hoursToRemove);
        },
        addWork(shiftId, day) {
          const shift = this.views.shift(shiftId);
          if (!shift.exists) {
            // eslint-disable-next-line no-console
            console.log('Missing shift', shiftId);
            return;
          }
          const timeWorked = shift.hoursWorked;

          this.addWorkToDay(day, timeWorked[0]);

          if (shift.isOvernight) {
            this.addWorkToDay(nextDay(day), timeWorked[1]);

            if (this.info.dayIsInPeriod(day)) {
              this.overnights += 1;
            }

            const week = dayToWeek(day);
            const weekOvernights = this.overnightsByWeek.has(week)
              ? this.overnightsByWeek.get(week)
              : 0;
            this.overnightsByWeek.set(week, weekOvernights + 1);

            const month = dayToMonth(day);
            const monthOvernights = this.overnightsByMonth.has(month)
              ? this.overnightsByMonth.get(month)
              : 0;
            this.overnightsByMonth.set(month, monthOvernights + 1);
          }
        },
        removeWork(shiftId, day) {
          const shift = this.views.shift(shiftId);
          if (!shift.exists) {
            // eslint-disable-next-line no-console
            console.log('Missing shift', shiftId);
            return;
          }
          const timeWorked = shift.hoursWorked;

          this.removeWorkToDay(day, timeWorked[0]);

          if (shift.isOvernight) {
            this.removeWorkToDay(nextDay(day), timeWorked[1]);
            if (this.info.dayIsInPeriod(day)) {
              this.overnights -= 1;
            }

            const week = dayToWeek(day);
            const weekOvernights = this.overnightsByWeek.has(week)
              ? this.overnightsByWeek.get(week)
              : 0;
            this.overnightsByWeek.set(week, weekOvernights - 1);

            const month = dayToMonth(day);
            const monthOvernights = this.overnightsByMonth.has(month)
              ? this.overnightsByMonth.get(month)
              : 0;
            this.overnightsByMonth.set(month, monthOvernights - 1);
          }
        },
        observeChange(day, shiftMap) {
          if (!this.info.dayIsInPeriod(day)) return;

          [...shiftMap.keys()].forEach(shiftId => {
            this.addWork(shiftId, day);
          });

          this.observerDisposer[day] = observe(shiftMap, change => {
            if (change.type === 'add') {
              this.addWork(change.name, day);
            } else if (change.type === 'delete') {
              this.removeWork(change.name, day);
            } else {
              throw Error('Should not update shift map');
            }
          });
        },
        stopObserving(day, shiftIds) {
          shiftIds.forEach(shiftId => {
            this.removeWork(shiftId, day);
          });
          this.observerDisposer[day]();
          delete this.observerDisposer[day];
        },
      },
      {
        addWorkToDay: action.bound,
        removeWorkToDay: action.bound,
        addWork: action.bound,
        removeWork: action.bound,
        observeChange: action.bound,
        stopObserving: action.bound,
      }
    );

    if (!this.allAttributions.staffDayShift.has(staffId)) {
      this.allAttributions.staffDayShift.set(staffId, observable.map());
    }

    [...this.allAttributions.staffDayShift.get(staffId).entries()].forEach(([day, shifts]) => {
      this.observeChange(day, shifts);
    });
    observe(this.allAttributions.staffDayShift.get(staffId), change => {
      if (change.type === 'add') {
        this.observeChange(change.name, change.newValue);
      } else if (change.type === 'delete') {
        this.stopObserving(change.name, [...change.oldValue.keys()]);
      } else {
        throw Error('Should not update day shift map');
      }
    });
  }
}

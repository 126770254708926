import React from 'react';
import styled from 'styled-components/macro';
import { darken } from 'polished';

import { observer } from 'mobx-react';

import { color } from '../theme';

import { injectInProject as inject } from '../state/utils';

import Logo from '../icons/Logo.jsx';
import Menu from '../icons/Menu.jsx';
import SlidingMenu from '../components/SlidingMenu.jsx';

import WhenModeIs from './WhenModeIs.jsx';
import { EditorNav, ViewerNav, ScheduleNav, AdminNav } from './MainNavigation.jsx';

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 2000;

  padding-left: 20px;
  padding-right: 20px;

  height: 50px;
  min-height: 50px;
  line-height: 50px;

  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background-color: ${color(3)};
  color: white;
  fill: white;

  & > * {
    flex: 0 0 auto;
  }

  @media print {
    display: none;
  }
`;

const Title = styled.a`
  text-decoration: none;
  user-select: none;
  display: flex;
  margin-left: auto;
  cursor: pointer;

  height: 50px;

  & > * {
    flex: 0 0 auto;
  }

  & > :not(:first-child) {
    margin-left: 10px;
  }
`;

const NavWrapper = styled.div`
  margin-right: 20px;

  @media (max-width: 550px) {
    display: none;
  }
`;

const ShortNavWrapper = styled.div`
  margin-right: 20px;

  @media (min-width: 551px) {
    display: none;
  }
`;

const PlacedMenu = styled(({ opened, ...props }) => <Menu {...props} />)`
  fill: ${darken(0.2, 'white')};
  ${props => props.opened && 'transform: rotate(180deg); fill: white;'};
  transition: all 300ms ease-out;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
`;

export default inject('GlobalUI')(
  observer(
    class HeaderWithMenu extends React.Component {
      constructor(props) {
        super(props);
        this.header = React.createRef();
      }

      render() {
        const { GlobalUI } = this.props;
        return (
          <>
            <SlidingMenu
              targetRef={this.header}
              isOpen={GlobalUI.menuOpen}
              onRequestClose={GlobalUI.closeMenu}
            />
            <HeaderWrapper ref={this.header}>
              <PlacedMenu onClick={GlobalUI.toggleMenu} opened={GlobalUI.menuOpen} width="1.3em" />
              <WhenModeIs mode="viewer">
                <ShortNavWrapper>
                  <ScheduleNav />
                </ShortNavWrapper>
              </WhenModeIs>

              <NavWrapper>
                <WhenModeIs mode="editor">
                  <EditorNav />
                </WhenModeIs>
                <WhenModeIs mode="viewer">
                  <ViewerNav />
                </WhenModeIs>
                <WhenModeIs mode="admin">
                  <AdminNav />
                </WhenModeIs>
              </NavWrapper>

              <Title onClick={GlobalUI.resetScreen}>
                <span>Horair.es</span>
                <Logo width="30px" />
              </Title>
            </HeaderWrapper>
          </>
        );
      }
    }
  )
);

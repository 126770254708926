import { observable, action, extendObservable } from 'mobx';
import { addDays, differenceInDays, differenceInMonths, startOfMonth, endOfMonth } from 'date-fns';

import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import range from 'lodash/range';
import fromPairs from 'lodash/fromPairs';

import {
  dayToWeek,
  dayToMonth,
  parseDay,
  formatDay,
  previousDay,
  nextDay,
  formatWeek,
  formatMonth,
  parseMonth,
  buildYearMonthObject,
  buildMonthDate,
} from '../dates';
import staffModel from '../models/staff';

const iterateDays = (start, duration, iteratee) => {
  const day = parseDay(start);
  range(duration).forEach(n => iteratee(formatDay(addDays(day, n))));
};

const asMonth = yearMonth => formatMonth(buildMonthDate(yearMonth));
const asYearMonth = month => buildYearMonthObject(parseMonth(month));

export default class StaffView {
  constructor(staffId, allStaffs, allShifts, allAttributions, allAbsences, info) {
    if (!allStaffs.has(staffId)) {
      throw new Error(`${JSON.stringify(staffId)} does not exist as a staff`);
    }
    const api = staffModel.extendWithGetters({
      /* See previous listing */

      staffId,
      allStaffs,
      allShifts,
      allAttributions,
      allAbsences,
      info,

      get raw() {
        return this.allStaffs.get(this.staffId);
      },

      get nameError() {
        const errors = [];
        if (!this.name) errors.push('You must define a full name');
        return errors.join(', ');
      },

      get shorthandError() {
        const errors = [];
        if (!this.shorthand) errors.push('You must define a shorthand');
        return errors.join(', ');
      },

      setCategories(categories) {
        this.allStaffs.get(this.staffId).categories = observable.map(
          fromPairs(categories.map(c => [c, true]))
        );
      },

      get hasErrors() {
        return this.nameError || this.shorthandError;
      },

      delete() {
        [...this.staffAttributions.entries()].forEach(([day, shifts]) =>
          [...shifts.keys()].forEach(shiftId =>
            this.allAttributions.deleteAttribution(day, this.staffId, shiftId)
          )
        );
        this.absences.forEach(absence => {
          this.allAbsences.deleteAbsence(this.staffId, absence.day);
        });

        this.allStaffs.deleteStaff(this.staffId);
      },

      deleteShift(day, shiftId) {
        this.allAttributions.deleteAttribution(day, this.staffId, shiftId);
      },

      deleteAbsence(day) {
        this.allAbsences.deleteAbsence(this.staffId, day);
      },

      get staffAttributions() {
        if (this.allAttributions.staffDayShift.has(this.staffId)) {
          return this.allAttributions.staffDayShift.get(this.staffId);
        }
        return observable.map();
      },

      plannedThatDay(day) {
        return this.staffAttributions.has(day) && this.staffAttributions.get(day).size > 0;
      },

      consecutiveDaysPlanned(day) {
        if (!this.plannedThatDay(day)) return 0;

        let start = day;
        let end = day;

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const newStart = previousDay(start);
          if (!this.plannedThatDay(newStart) || !this.info.dayIsInPeriod(newStart)) {
            break;
          }
          start = newStart;
        }
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const newEnd = nextDay(end);
          if (!this.plannedThatDay(newEnd) || !this.info.dayIsInPeriod(newEnd)) {
            break;
          }
          end = newEnd;
        }
        return differenceInDays(parseDay(end), parseDay(start)) + 1;
      },

      shiftsWorked(day) {
        if (!this.staffAttributions.has(day)) return [];
        return sortBy(
          [...this.staffAttributions.get(day).keys()]
            .map(shiftId => this.allShifts.get(shiftId))
            .filter(shift => shift),
          shift => parseInt(shift.startTime.split(':'), 10)
        );
      },

      toggleShift(day, shift) {
        this.allAttributions.toggle(this.staffId, day, shift);
      },

      addShift(day, shift) {
        if (this.isAbsent(day)) return;
        this.allAttributions.set(day, this.staffId, shift);
      },

      shiftDays(shift) {
        const { staffShiftDay } = this.allAttributions;
        if (staffShiftDay.has(this.staffId) && staffShiftDay.get(this.staffId).has(shift)) {
          return [
            ...staffShiftDay
              .get(this.staffId)
              .get(shift)
              .keys(),
          ];
        }
        return [];
      },

      get absences() {
        return this.allAbsences.all.filter(absence => absence.staffId === this.staffId);
      },

      get absencesByWeek() {
        return observable.map(groupBy(this.absences, absence => dayToWeek(absence.day)));
      },

      isAbsent(day) {
        return this.allAbsences.isAbsent(this.staffId, day);
      },

      absence(day) {
        return this.allAbsences.get(this.staffId, day);
      },

      deleteAbsences(start, duration) {
        iterateDays(start, duration, day => this.allAbsences.deleteAbsence(this.staffId, day));
      },

      addAbsences(start, duration, type, comment) {
        iterateDays(start, duration, day => {
          if (this.plannedThatDay(day)) return;
          let localType = type;
          if (this.info.isHoliday(day)) {
            localType = 'Unpaid';
          }
          this.allAbsences.set(this.staffId, day, localType, comment);
        });
      },

      get rateCorrection() {
        if (this.activityRate === 100 || !this.activityRate) return 1;
        return 100 / this.activityRate;
      },

      get absencesInPeriod() {
        return this.absences.filter(({ day }) => this.info.dayIsInPeriod(day));
      },

      get unpaidLeave() {
        return this.absencesInPeriod.filter(
          ({ type, day }) => type === 'Unpaid' || this.info.isHoliday(day)
        ).length;
      },
      get vacationTaken() {
        return this.absencesInPeriod.filter(
          ({ type, day }) => type === 'Vacation' && !this.info.isHoliday(day)
        ).length;
      },
      get formationTaken() {
        return this.absencesInPeriod.filter(
          ({ type, day }) => type === 'Formation' && !this.info.isHoliday(day)
        ).length;
      },
      get otherAbsencesTaken() {
        return this.absencesInPeriod.filter(
          ({ type, day }) => type === 'Other' && !this.info.isHoliday(day)
        ).length;
      },

      worksShift(day, shiftId) {
        return this.allAttributions.exists(this.staffId, day, shiftId);
      },

      isOutsidePlanning(date) {
        const dateMonth = dayToMonth(date);
        if (this.arrivalMonth && dateMonth < this.arrivalMonth) return true;
        if (this.departureMonth && dateMonth > this.departureMonth) return true;

        return false;
      },

      get arrivalToDeparture() {
        let { startMonth, endMonth } = this.info;
        if (this.arrivalMonth && this.arrivalMonth > asMonth(startMonth)) {
          startMonth = asYearMonth(this.arrivalMonth);
        }
        if (this.departureMonth && this.departureMonth < asMonth(endMonth)) {
          endMonth = asYearMonth(this.departureMonth);
        }

        return { startMonth, endMonth };
      },

      get firstWeek() {
        const { startMonth } = this.arrivalToDeparture;
        return formatWeek(startOfMonth(buildMonthDate(startMonth)));
      },

      get lastWeek() {
        const { endMonth } = this.arrivalToDeparture;
        return formatWeek(endOfMonth(buildMonthDate(endMonth)));
      },

      get durationUsed() {
        if (!this.arrivalMonth && !this.departureMonth) return this.info.duration;
        const { endMonth, startMonth } = this.arrivalToDeparture;

        return differenceInMonths(buildMonthDate(endMonth), buildMonthDate(startMonth)) + 1;
      },
    });
    extendObservable(this, api, {
      setCategories: action.bound,
      delete: action.bound,
      deleteAbsence: action.bound,
      deleteShift: action.bound,
      toggleShift: action.bound,
      deleteAbsences: action.bound,
      addAbsences: action.bound,
      addShift: action.bound,
    });
  }
}

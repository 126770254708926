import isUndefined from 'lodash/isUndefined';
import fromPairs from 'lodash/fromPairs';

export class Field {
  constructor({ update, validate, extract } = {}) {
    this.updateFcn = update;
    this.validationFcn = validate;
    this.extractFcn = extract;

    this.extract = this.extract.bind(this);
    this.update = this.update.bind(this);
  }

  extract(value) {
    if (this.extractFcn) return this.extractFcn(value);
    return value;
  }

  update(newVal, previousVal) {
    if (this.updateFcn) return this.updateFcn(newVal, previousVal);
    return newVal;
  }
}

export class Model {
  constructor(model) {
    this.model = model;

    this.extract = this.extract.bind(this);
    this.update = this.update.bind(this);
  }

  extendWithGetters(view) {
    // This allows us to have the views fields defined from the model

    let outView = view;
    const self = this;
    Object.keys(this.model).forEach(field => {
      outView = Object.defineProperty(outView, field, {
        enumerable: true,
        // eslint-disable-next-line object-shorthand, func-names
        get: function() {
          return view.raw[field];
        },

        // eslint-disable-next-line object-shorthand, func-names
        set: function(value) {
          self.update(view.raw, { [field]: value });
        },
      });
    });

    return view;
  }

  extract(data) {
    const key = data.id;

    const value = fromPairs(
      Object.keys(this.model).map(field => [field, this.model[field].extract(data[field])])
    );
    return { key, value };
  }

  update(data, newData) {
    Object.keys(this.model).forEach(field => {
      if (!isUndefined(newData[field])) {
        // eslint-disable-next-line no-param-reassign
        data[field] = this.model[field].update(newData[field], data[field]);
      }
    });
  }
}

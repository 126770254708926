import { observable, observe, action, reaction, toJS } from 'mobx';
import entries from 'lodash/entries';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

const buildKey = (day, staffId) => JSON.stringify([day, staffId]);

export const ABSENCE_INFO = {
  Vacation: {
    name: 'Vacation',
    shortname: {
      en: 'V',
      fr: 'V',
    },
  },
  Formation: {
    name: 'Formation',
    shortname: {
      en: 'F',
      fr: 'F',
    },
  },
  Other: {
    name: 'Other absence',
    shortname: {
      en: 'O',
      fr: 'A',
    },
  },
  Unpaid: {
    name: 'Unpaid days',
    shortname: {
      en: 'Ø',
      fr: 'Ø',
    },
  },
};

export const absenceNameFromId = absenceId => {
  const info = ABSENCE_INFO[absenceId];
  if (!info) return '??UNKNOWN??';
  return ABSENCE_INFO[absenceId].name;
};

const extractAbsence = absence => {
  const key = absence.id;
  if (key !== buildKey(absence.day, absence.staffId)) {
    throw new Error('Inconsistent absence change');
  }

  const value = {
    day: absence.day,
    staffId: absence.staffId,
    type: absence.type,
    comment: absence.comment,
  };
  return { key, value };
};

export default () =>
  observable(
    {
      raw: observable.map(),
      project: null,

      init(project) {
        this.project = project;
        return project.fetchValues('absence').then(keyValues => {
          keyValues.forEach(([key, value]) => {
            if (key.startsWith('absence-[{')) {
              // eslint-disable-next-line no-console
              console.log('ignoring rotten key');
              return;
            }
            this.set(value.staffId, value.day, value.type, value.comment);
          });
        });
      },

      startObserve() {
        const onChange = this.project.onChangeHandler.bind(this.project, 'absence');
        this._observer = observe(this.raw, onChange);
      },

      load(absences) {
        absences.forEach(({ value }) =>
          this.set(value.staffId, value.day, value.type, value.comment)
        );
      },

      dump() {
        return [...this.raw.entries()].map(([key, value]) => ({ key, value: toJS(value) }));
      },

      deleteAbsence(staffId, day) {
        const key = buildKey(day, staffId);

        if (this.raw.has(key)) {
          this.raw.delete(key);
        }
      },

      set(staffId, day, type = 'Vacation', comment) {
        const key = buildKey(day, staffId);
        const absence = observable({ id: key, day, staffId, type, comment });

        if (this.project) {
          reaction(
            () => extractAbsence(absence),
            ({ key: key_, value }) => this.project.update('absence', key_, value),
            { delay: 1000 }
          );
        }
        this.raw.set(key, absence);
      },

      get(staffId, day) {
        const key = buildKey(day, staffId);
        return this.raw.get(key);
      },

      toggle(staffId, day) {
        const key = buildKey(day, staffId);

        if (this.raw.has(buildKey(day, staffId))) {
          this.raw.delete(key);
        } else {
          this.set(staffId, day);
        }
      },

      isAbsent(staffId, day) {
        return this.raw.has(buildKey(day, staffId));
      },

      absenceType(staffId, day) {
        const key = buildKey(day, staffId);
        return this.raw.has(key) && this.raw.get(key).type;
      },

      get all() {
        return sortBy([...this.raw.values()], val => [val.day, val.staffId]);
      },

      update(key, { staffId, day, type, comment }) {
        if (!this.raw.has(key)) {
          this.set(staffId, day, type, comment);
          return;
        }

        const rawValue = this.raw.get(key);

        if (type) rawValue.type = type;
        if (comment) rawValue.comment = comment;
      },

      updates(changes) {
        if (changes.deletions) {
          changes.deletions.forEach(id => {
            if (this.raw.has(id)) this.raw.delete(id);
          });
        }

        if (changes.updates) {
          entries(changes.updates)
            .filter(([, update]) => !isEmpty(update))
            .forEach(([key, update]) => this.update(key, update));
        }
      },

      get isEmpty() {
        return !this.raw.size;
      },
    },
    {
      init: action.bound,
      load: action.bound,
      deleteAbsence: action.bound,

      set: action.bound,
      toggle: action.bound,
    }
  );

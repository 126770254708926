import React, { Suspense } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { observable } from 'mobx';
import { Provider, observer } from 'mobx-react';

import theme from './theme';
import StoreContext, { UIMemoryContext } from './StoreContext';
import OfflineModal from './components/OfflineModal.jsx';
import ErrorInfo from './components/ErrorInfo.jsx';
import Menu from './components/SlidingMenu.jsx';
import OldBrowserModal from './components/OldBrowserModal.jsx';

import Header from './ui-base/Header.jsx';
import Footer from './ui-base/Footer.jsx';
import GlobalError from './ui-base/GlobalError.jsx';
import WhenModeIs from './ui-base/WhenModeIs.jsx';
import Loading from './ui-base/Loading.jsx';
import PageTitle from './ui-base/PageTitle.jsx';

import { injectInProject as inject } from './state/utils';
import UI from './state';

const EditorMode = React.lazy(() => import('./modes/EditorMode.jsx'));
const EditorModeHeader = React.lazy(() => import('./modes/EditorModeHeader.jsx'));
const ViewerMode = React.lazy(() => import('./modes/ViewerMode.jsx'));
const AdminMode = React.lazy(() => import('./modes/AdminMode.jsx'));

const UIMemory = observable.map();

const MainWrapper = styled.div`
  min-width: 100%;

  min-height: 100vh;

  margin: 0;
  padding-top: 50px;

  display: flex;
  flex-direction: column;

  transition: all 300ms ease-out;

  ${props => props.menuOpen && `transform: translateX(250px);`};

  @media print {
    display: block;
    min-height: 100%;
    padding-top: 0px;
  }
`;
const Main = inject('GlobalUI')(
  observer(({ GlobalUI, ...props }) => <MainWrapper menuOpen={GlobalUI.menuOpen} {...props} />)
);

const BodyWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;

  flex-direction: column;

  @media print {
    overflow: visible;
    display: block;
  }
`;

const Blank = () => null;

const App = observer(() => {
  let Body = Blank;
  let PageHeader = Blank;

  if (UI.mode === 'editor') {
    Body = EditorMode;
    PageHeader = EditorModeHeader;
  } else if (UI.mode === 'viewer') {
    Body = ViewerMode;
  } else if (UI.mode === 'admin') {
    Body = AdminMode;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalError>
        <Provider GlobalUI={UI} UIMemory={UIMemory}>
          <StoreContext.Provider value={UI}>
            <UIMemoryContext.Provider value={UIMemory}>
              <>
                <PageTitle GlobalUI={UI} />
                <Header UIState={UI} />
                <Main>
                  <Suspense fallback={<Blank />}>
                    <PageHeader />
                  </Suspense>
                  <BodyWrapper>
                    <Suspense fallback={<Loading />}>
                      <Body />
                    </Suspense>
                    <Footer />
                  </BodyWrapper>
                  <OfflineModal />
                  <ErrorInfo />
                </Main>
                <Menu>Bim</Menu>
                <WhenModeIs mode="editor">
                  <OldBrowserModal />
                </WhenModeIs>
                <WhenModeIs mode="viewer">
                  <OldBrowserModal versions={{ Safari: '>=11' }} />
                </WhenModeIs>
              </>
            </UIMemoryContext.Provider>
          </StoreContext.Provider>
        </Provider>
      </GlobalError>
    </ThemeProvider>
  );
});

export default App;

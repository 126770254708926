import isEmpty from 'lodash/isEmpty';
import { observable } from 'mobx';

import { startOfWeek, subWeeks } from 'date-fns';

import createUIStore from './project-ui';
import createBackend from './project-backend';
import Publications from './publications';

import { getProjectData } from './backend-interactions';

import createShiftsStore from './stores/shifts';
import createStaffsStore from './stores/staffs';
import createAttributionsStore from './stores/attributions';
import createAbsencesStore from './stores/absences';
import createInfoStore from './stores/project-info';

import createViews from './views';
import { formatDay } from './dates';

export default class Project {
  constructor(name, id) {
    this.id = id;

    this.project = null;

    this.infoStore = createInfoStore(name);
    this.shiftsStore = createShiftsStore();
    this.staffsStore = createStaffsStore();
    this.attributionsStore = createAttributionsStore();
    this.absencesStore = createAbsencesStore();
    this.metaStore = observable({ unitsPaid: 0 });

    this.views = createViews(
      this.staffsStore,
      this.shiftsStore,
      this.attributionsStore,
      this.absencesStore,
      this.infoStore
    );
    this.UIStateStore = createUIStore(this.staffsStore, this.shiftsStore, this.views);
    this.backend = createBackend(this.id, this.updates.bind(this));
    this.publications = new Publications(this.id, this.backend);

    this.importTypeFrom = this.importTypeFrom.bind(this);

    this.previousProject = null;
    this.missingPreviousProject = false;
    this.previousProjectLoading = Promise.resolve();
  }

  init() {
    this.project = this.backend.newProjectSync();
    //eslint-disable-next-line camelcase
    this.project.data.then(({ units_paid: unitsPaid }) => {
      this.metaStore.unitsPaid = unitsPaid;
    });

    return Promise.all([
      this.infoStore.init(this.project),
      this.shiftsStore.init(this.project),
      this.staffsStore.init(this.project),
      this.attributionsStore.init(this.project),
      this.absencesStore.init(this.project),
    ]).then(() => {
      this.UIStateStore.endLoad();
      this.shiftsStore.startObserve();
      this.staffsStore.startObserve();
      this.absencesStore.startObserve();
      this.attributionsStore.startObserve();
    });
  }

  stop() {
    if (this.project) {
      this.project.stop();
    }
  }

  load(version = 'latest') {
    return this.publications.rawFetchPublication(version).then(publication => {
      this.loads(publication);
      this.UIStateStore.endLoad();
      return (publication.meta || {}).timestamp;
    });
  }

  loadAfter(date) {
    return this.backend.getProjectAfter(date).then(publication => {
      this.loads(publication);
      this.UIStateStore.endLoad();
    });
  }

  loads(data) {
    if (data.info) this.infoStore.load(data.info);
    if (data.shifts) this.shiftsStore.load(data.shifts);
    if (data.staffs) this.staffsStore.load(data.staffs);
    if (data.attributions) this.attributionsStore.load(data.attributions);
    if (data.absences) this.absencesStore.load(data.absences);

    //eslint-disable-next-line camelcase
    if (data.units_paid) this.metaStore.units_paid = data.units_paid;
  }

  updates(data) {
    if (data.info) this.infoStore.updates(data.info);
    if (data.shifts) this.shiftsStore.updates(data.shifts);
    if (data.staffs) this.staffsStore.updates(data.staffs);
    if (data.attributions) this.attributionsStore.updates(data.attributions);
    if (data.absences) this.absencesStore.updates(data.absences);

    //eslint-disable-next-line camelcase
    if (data.units_paid) this.metaStore.units_paid = data.units_paid;
  }

  dumps() {
    return {
      info: this.infoStore.dump(),
      shifts: this.shiftsStore.dump(),
      staffs: this.staffsStore.dump(),
      attributions: this.attributionsStore.dump(),
      absences: this.absencesStore.dump(),
    };
  }

  dump() {
    return JSON.stringify(this.dumps());
  }

  importTypeFrom(type, projectId) {
    let store = null;
    if (type === 'staff') {
      store = this.staffsStore;
    } else if (type === 'shift') {
      store = this.shiftsStore;
    } else {
      return;
    }

    getProjectData(projectId).then(data => {
      const typeData = data[`${type}s`];
      if (!isEmpty(typeData)) {
        store.import(typeData);
      }
    });
  }

  loadPrevious() {
    if (
      this.previousProject &&
      this.infoStore &&
      this.infoStore.previousProject &&
      this.infoStore.previousProject === this.previousProject.id
    )
      return this.previousProjectLoading;

    this.previousProject = new Project('', this.infoStore.previousProject);

    const firstDay = formatDay(startOfWeek(subWeeks(this.infoStore.startMonthAsDate, 2)), {
      weekStartsOn: 1,
    });
    this.previousProjectLoading = this.previousProject
      .loadAfter(firstDay)
      .then(() => {
        this.UIStateStore.missingPreviousProject = false;
      })
      .catch(() => {
        this.UIStateStore.missingPreviousProject = true;
        this.previousProject = null;
      });

    return this.previousProjectLoading;
  }
}

import React from 'react';
import styled from 'styled-components/macro';

import { observer } from 'mobx-react';

import WhenCurrentProject from '../ui-base/WhenCurrentProject.jsx';
import { Button, CancelButton, ButtonBar } from '../ui-base/Button.jsx';
import { injectInProject as inject } from '../state/utils';
import Translate from './Translate.jsx';

export const ErrorButton = styled(Button)`
  background-color: white;
  color: #bd0000;
  border-color: #bd0000;

  &:active {
    position: relative;
    top: 1px;
  }

  &:hover {
    background-color: #ffffff9b;
    border-color: transparent;
    border-radius: 2px;
  }
`;

export const ErrorCancelButton = styled(CancelButton)`
  color: white;
`;

const FloatingMenuWrapper = styled.div`
  z-index: 5000;
  background-color: #bd0000;

  color: white;
  fill: white;

  max-width: 300px;

  position: absolute;
  top: 20px;
  left: 50px;
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  padding: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);

  border-radius: 2px;
`;

export default inject('backend')(
  observer(({ backend }) => {
    if (!backend || !backend.connectionError) return null;

    return (
      <WhenCurrentProject>
        <FloatingMenuWrapper>
          <p>
            <Translate>There was an error saving your data.</Translate>
          </p>
          <p>
            <Translate>
              If you do not reload this page you might encounter some data loss.
            </Translate>
          </p>
          <ButtonBar>
            <ErrorButton onClick={() => document.location.reload()}>
              <Translate>Reload</Translate>
            </ErrorButton>
            <ErrorCancelButton onClick={backend.clearErrors}>
              <Translate>Ignore</Translate>
            </ErrorCancelButton>
          </ButtonBar>
        </FloatingMenuWrapper>
      </WhenCurrentProject>
    );
  })
);

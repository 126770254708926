import React from 'react';
import styled from 'styled-components/macro';
import ReactModal from 'react-modal';

const ModalTitle = styled.div`
  padding-bottom: 0.5em;
  font-size: 1.2em;
`;

const XCross = styled(({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 8 8" {...rest}>
    <line x1="2" y1="2" x2="6" y2="6" stroke="currentColor" strokeLinecap="round" />
    <line x1="2" y1="6" x2="6" y2="2" stroke="currentColor" strokeLinecap="round" />
  </svg>
))`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderStyle: 'none',
    transform: 'translate(-50%, -50%)',
    borderRadius: '2px',
    maxHeight: '95%',
    overflow: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '10000',
  },
};

// eslint-disable-next-line
export default class Modal extends React.Component {
  render() {
    const { title, withCloseCross, onRequestClose, children, fixedSize, ...rest } = this.props;

    const style = { content: { ...customStyles.content }, overlay: { ...customStyles.overlay } };
    if (fixedSize) {
      style.content.overflow = 'visible';
    }

    return (
      <ReactModal ariaHideApp={false} onRequestClose={onRequestClose} {...rest} style={style}>
        {withCloseCross && onRequestClose && <XCross size="1.2em" onClick={onRequestClose} />}
        {title && <ModalTitle>{title}</ModalTitle>}
        {children}
      </ReactModal>
    );
  }
}

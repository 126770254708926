import React from 'react';
import styled from 'styled-components/macro';

import { color } from '../theme';

const Footer = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  flex: 0 0 100%;

  height: 50px;
  min-height: 50px;
  max-height: 50px;

  line-height: 50px;

  display: flex;
  justify-content: flex-end;

  color: ${color(2)};

  @media print {
    display: none;
  }
`;

const FootInfo = styled.span`
  font-size: small;
`;

export default () => (
  <Footer>
    <FootInfo>QuaroTech</FootInfo>
  </Footer>
);

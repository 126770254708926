import { useContext } from 'react';
import { inject } from 'mobx-react';
import Humanize from 'humanize-plus';

import StoreContext, { UIMemoryContext } from '../StoreContext';

const PROJECT_STORES = {
  UIState: 'UIStateStore',
  views: 'views',
  staffs: 'staffsStore',
  shifts: 'shiftsStore',
  projectInfo: 'infoStore',
  backend: 'backend',
  publications: 'publications',
  meta: 'metaStore',
};

export const injectInProject = (...args) =>
  inject((stores, inProps) => {
    const props = { ...inProps };

    args.forEach(prop => {
      if (PROJECT_STORES[prop] && stores.GlobalUI.currentProject) {
        props[prop] = stores.GlobalUI.currentProject[PROJECT_STORES[prop]];
      } else {
        props[prop] = stores[prop];
      }
    });

    return props;
  });

export const rounded = value => Humanize.toFixed(value, 1).replace(/\.0$/, '');

export const useStore = (...args) => {
  const stores = useContext(StoreContext);
  const props = {};

  args.forEach(prop => {
    if (prop === 'GlobalUI') {
      props[prop] = stores;
    } else if (PROJECT_STORES[prop] && stores.currentProject) {
      props[prop] = stores.currentProject[PROJECT_STORES[prop]];
    } else {
      props[prop] = stores[prop];
    }
  });

  return props;
};

export const useCalendarView = () => {
  const store = useContext(StoreContext);
  return store.currentProject.views.calendar;
};

export const useProjectInfo = () => {
  const store = useContext(StoreContext);
  return store.currentProject.infoStore;
};

export const useUIMemory = () => useContext(UIMemoryContext);

import React from 'react';

import { observer, inject } from 'mobx-react';

import Translate from './Translate.jsx';
import Modal from '../ui-base/Modal.jsx';

export default inject('GlobalUI')(
  observer(({ GlobalUI }) => (
    <Modal isOpen={GlobalUI.offline}>
      <h3>
        <Translate>You are offline</Translate>
      </h3>
      <p>
        <Translate>This tool only works if you are online.</Translate>
      </p>
      <p>
        <Translate>Reconnect to be able to use edit your Horair.es.</Translate>
      </p>
    </Modal>
  ))
);

import React from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import { translate } from '../components/Translate.jsx';

/* eslint-disable camelcase */
const editorTitleMap = {
  grid: 'Grid',
  absences: 'Absences',
  summary: 'Summary',
  project: 'Info',
  edit_staff: 'Staff',
  edit_shift: 'Shift',
  print: 'Print',
  share: 'Share',
  publication: 'Publish',
};

const viewerTitleMap = {
  individual_schedule: 'Schedule',
  full_individual_schedule: 'Schedule',
  individual_calendar: 'Calendar',
  full_individual_calendar: 'Calendar',
  overview_schedule: 'Overview',
  full_overview_schedule: 'Overview',
  individual_absences: 'Absences',
};
/* eslint-enable camelcase */

export default observer(({ GlobalUI }) => {
  let title = 'Horair.es';
  if (GlobalUI.mode === 'editor' && editorTitleMap[GlobalUI.screen]) {
    title = `${translate(editorTitleMap[GlobalUI.screen], GlobalUI.lang)} | ${title}`;
  }

  if (GlobalUI.mode === 'viewer' && viewerTitleMap[GlobalUI.screen]) {
    title = `${translate(viewerTitleMap[GlobalUI.screen], GlobalUI.lang)} | ${title}`;
  }

  if (GlobalUI.mode === 'admin') {
    title = `Admin | ${title}`;
  }

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
});

import { observable, action, flow } from 'mobx';

import {
  getProjectAfter,
  fetchPermissions,
  inviteUser,
  removeUser,
  publishProject,
  BackendSavedProject,
} from './backend-interactions';

export default (projectId, updateFcn) =>
  observable(
    {
      projectId,
      connectionError: null,

      loadingPermission: false,
      permissions: {},

      clearErrors() {
        this.connectionError = null;
      },
      reportError(error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.connectionError = error;
      },

      fetchPermissions: flow(function* fetchPermissionsM() {
        this.loadingPermissions = true;
        try {
          const results = yield fetchPermissions(this.projectId);
          this.loadingPermissions = false;
          this.permissions = results;
        } catch (error) {
          this.loadingPermissions = false;
          this.connectionError = error;
        }
      }),

      inviteUser: flow(function* inviteUserM(email, lang) {
        this.loadingPermissions = true;
        try {
          const results = yield inviteUser(this.projectId, email, lang);
          this.loadingPermissions = false;
          this.permissions = results;
        } catch (error) {
          this.loadingPermissions = false;
          this.connectionError = error;
        }
      }),

      removeUser: flow(function* removeUserM(email) {
        this.loadingPermissions = true;
        try {
          const results = yield removeUser(this.projectId, email);
          this.loadingPermissions = false;
          this.permissions = results;
        } catch (error) {
          this.loadingPermissions = false;
          this.connectionError = error;
        }
      }),

      publishProject(lastDayToPublish = '') {
        return publishProject(this.projectId, lastDayToPublish).catch(error => {
          this.connectionError = error;
        });
      },

      getProjectAfter(after) {
        return getProjectAfter(this.projectId, after).catch(error => {
          this.connectionError = error;
        });
      },

      newProjectSync() {
        return new BackendSavedProject(this.projectId, this, updateFcn);
      },
    },
    {
      clearErrors: action.bound,
      reportError: action.bound,

      fetchPermissions: action.bound,

      inviteUser: action.bound,
      removeUser: action.bound,

      publishProject: action.bound,

      newProjectSync: action.bound,
    }
  );

import { extendObservable } from 'mobx';
import { map } from 'itertools';

import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import sortBy from 'lodash/sortBy';

import calendarView from './calendar';
import StaffView from './staff';
import ShiftView from './shift';
import WorkedTimeView from './workTime';

const sortedKeys = groups => sortBy(uniq(flatMap(groups, group => [...group.categories.keys()])));

const clean = string => string.toLowerCase().replace(' ', '');

export default (staffs, shifts, attributions, absences, info) => {
  const views = {};
  return extendObservable(views, {
    staff: memoize(id => new StaffView(id, staffs, shifts, attributions, absences, info)),
    staffByEmail(email) {
      const cleanEmail = clean(email);
      if (!cleanEmail) {
        return undefined;
      }
      return this.staffs.find(staff => clean(staff.email) === cleanEmail);
    },
    calendar: calendarView(info),
    get staffs() {
      return [...map(staffs.all, staff => this.staff(staff.id))];
    },

    get shiftCategories() {
      return sortedKeys([...shifts.raw.values()]);
    },

    get staffCategories() {
      return sortedKeys([...staffs.raw.values()]);
    },

    get categories() {
      return uniq([...this.shiftCategories, ...this.staffCategories]);
    },

    get staffGroups() {
      return uniq([...staffs.raw.values()].map(staff => staff.group).filter(e => e)).sort();
    },

    workedTime: memoize(id => new WorkedTimeView(id, views, attributions, info)),

    shift: memoize(id => new ShiftView(id, shifts, staffs, attributions, info)),
    get shifts() {
      return [...map(shifts.all, shift => this.shift(shift.id))];
    },

    get absences() {
      return absences.all;
    },

    get absenceComments() {
      return sortBy(
        uniq(
          [...absences.raw.values()]
            .map(absence => absence.comment && absence.comment.trim())
            .filter(comment => comment)
        )
      );
    },
  });
};

import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
if (
  !browser.satisfies({
    ie: '>10',
    edge: '>11',
    firefox: '>23',
    chrome: '>29',
    safari: '>9',
    opera: '>16',
    phantom: '>534',
  })
) {
  // eslint-disable-next-line no-undef, no-alert
  alert('Horair.es does not support your browser version. You need to use a more recent one.');
}

import { toJS } from 'mobx';

import { Field, Model } from './helper';

export default new Model({
  email: new Field(),
  name: new Field(),
  shorthand: new Field(),
  activityRate: new Field(),
  group: new Field(),
  vacationDays: new Field(),
  //eslint-disable-next-line camelcase
  additional_info: new Field(),

  arrivalMonth: new Field(),
  departureMonth: new Field(),

  categories: new Field({
    extract: toJS,
    update: (categories, previousCategories) => {
      previousCategories.replace(categories);
      return previousCategories;
    },
  }),
});

import React from 'react';
import styled, { css } from 'styled-components/macro';
import { extendObservable, action, autorun } from 'mobx';

import { inject, observer } from 'mobx-react';

import { darken } from 'polished';

const selectionChanges = ({ selected }) => {
  if (selected) {
    return css`
      color: inherit;
      fill: inherit;
    `;
  }

  return css`
    cursor: pointer;
    color: ${darken(0.2, 'white')};
    fill: ${darken(0.2, 'white')};
  `;
};

export const NavigationElement = styled.span`
  flex: 0 0 auto;
  display: inline-flex;
  padding-top: 5px;
  padding-bottom: 5px;

  ${selectionChanges};
  user-select: none;
  white-space: nowrap;

  :not(:last-child) {
    padding-right: 20px;
  }
`;

export const MultipleNavigation = inject('GlobalUI')(
  observer(
    class MultipleNavigation extends React.Component {
      constructor(props) {
        super(props);
        extendObservable(
          this,
          {
            to: props.default || props.screens[0],
            get currentlyEditor() {
              return this.props.screens.includes(this.props.GlobalUI.screen);
            },
            updateTo(newTo) {
              this.to = newTo;
            },
            changeScreen() {
              this.props.GlobalUI.changeScreen(this.to);
            },
          },
          {
            updateTo: action.bound,
            changeScreen: action.bound,
          }
        );
      }

      componentDidMount() {
        this.disposer = autorun(() => {
          if (this.currentlyEditor && this.props.GlobalUI.screen !== this.to) {
            this.updateTo(this.props.GlobalUI.screen);
          }
        });
      }

      componentWillUnmount() {
        if (this.disposer) this.disposer();
      }

      render() {
        return (
          <NavigationElement selected={this.currentlyEditor} onClick={this.changeScreen}>
            {this.props.children}
          </NavigationElement>
        );
      }
    }
  )
);

export default inject('GlobalUI')(
  observer(({ to, children, GlobalUI }) => (
    <NavigationElement selected={to === GlobalUI.screen} onClick={() => GlobalUI.changeScreen(to)}>
      {children}
    </NavigationElement>
  ))
);

const pathnameIsSelected = (to, GlobalUI) => GlobalUI.currentPathName === to;

export const NavigationLink = inject('GlobalUI')(
  observer(({ to, children, GlobalUI, isSelected = pathnameIsSelected }) => (
    <NavigationElement selected={isSelected(to, GlobalUI)} onClick={() => GlobalUI.navigateTo(to)}>
      {children}
    </NavigationElement>
  ))
);

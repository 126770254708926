import { observable, action } from 'mobx';

import { buildAllMonths, formatMonth, isHoliday } from '../dates';

export default info => {
  return observable(
    {
      isHoliday(day) {
        return info.isHoliday(day);
      },

      isCustomHoliday(day) {
        return info.isCustomHoliday(day);
      },

      isCustomWorkday(day) {
        return info.isCustomWorkday(day);
      },

      get allMonths() {
        return buildAllMonths(info.startMonth, info.endMonth).map(formatMonth);
      },

      toggleCustomHoliday(day) {
        if (isHoliday(day)) {
          if (this.isCustomWorkday(day)) info.raw.customWorkdays.delete(day);
          else info.raw.customWorkdays.add(day);
          return;
        }

        if (this.isCustomHoliday(day)) info.raw.customHolidays.delete(day);
        else info.raw.customHolidays.add(day);
      },
    },
    {
      toggleCustomHoliday: action.bound,
    }
  );
};

import { observable, action } from 'mobx';
import { map, sorted } from 'itertools';
import { v4 as uuid } from 'uuid';

import { buildMonthDate } from './dates';

export default () =>
  observable(
    {
      raw: observable.map(),

      set(id, { dbName, name, isAdmin = false, onServer = false, startMonth, endMonth }) {
        const project = observable({ id, name, dbName, isAdmin, onServer, startMonth, endMonth });
        this.raw.set(id, project);
      },

      get(projectId) {
        return this.raw.get(projectId);
      },

      delete(dbName) {
        return this.raw.delete(dbName);
      },

      new(name = 'New Project') {
        const dbName = `p${uuid()}`;
        this.set(dbName, { dbName, name });
        return dbName;
      },

      merge(key, { dbName, name, isAdmin, onServer, startMonth, endMonth }) {
        if (this.has(key)) {
          const p = this.get(key);
          if (dbName) p.dbName = dbName;
          if (onServer) p.onServer = onServer;
          if (isAdmin) p.isAdmin = isAdmin;
          if (startMonth) p.startMonth = startMonth;
          if (endMonth) p.endMonth = endMonth;
          return;
        }
        this.set(key, { dbName, name, isAdmin, onServer, startMonth, endMonth });
      },

      map(fcn) {
        return map(
          sorted(this.raw.values(), val =>
            val.startMonth ? -buildMonthDate(val.startMonth).getTime() : 0
          ),
          fcn
        );
      },

      has(projectId) {
        return this.raw.has(projectId);
      },
    },
    {
      set: action.bound,
      new: action.bound,
      merge: action.bound,
    }
  );

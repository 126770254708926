import { observable, action, extendObservable } from 'mobx';
import { isBefore, addDays, isValid, differenceInMinutes, format } from 'date-fns';

import fromPairs from 'lodash/fromPairs';

import { parseTime } from '../dates';
import shiftModel from '../models/shift';

const NEXT_DAY = addDays(parseTime('00:00'), 1);

export default class ShiftView {
  constructor(shiftId, allShifts, allStaffs, allAttributions, info) {
    const api = shiftModel.extendWithGetters({
      shiftId,
      allStaffs,
      allShifts,
      allAttributions,
      info,

      get raw() {
        return this.allShifts.get(this.shiftId);
      },
      get exists() {
        return this.allShifts.has(this.shiftId);
      },

      get descriptionError() {
        const errors = [];
        if (!this.description) errors.push('You must define a description');
        return errors.join(', ');
      },
      get shorthandError() {
        const errors = [];
        if (!this.shorthand) errors.push('You must define a shorthand');
        return errors.join(', ');
      },
      get startTimeError() {
        const errors = [];
        if (!this.startTime) errors.push('You must define a start time');
        else if (!isValid(this.startDatetime)) errors.push('Invalid start time');
        return errors.join(', ');
      },
      get endTimeError() {
        const errors = [];
        if (!this.endTime) errors.push('You must define a end time');
        else if (!isValid(this.endDatetime)) errors.push('Invalid end time');
        return errors.join(', ');
      },
      get hasErrors() {
        return (
          this.descriptionError || this.shorthandError || this.startTimeError || this.endTimeError
        );
      },

      setCategories(categories) {
        this.allShifts.get(this.shiftId).categories = observable.map(
          fromPairs(categories.map(c => [c, true]))
        );
      },

      setDuration(hours) {
        this.allShifts.get(this.shiftId).duration.hours = hours;
      },

      get startDatetime() {
        return parseTime(this.startTime);
      },

      get endDatetime() {
        const endDatetime = parseTime(this.endTime);
        if (isBefore(endDatetime, this.startDatetime)) {
          return addDays(endDatetime, 1);
        }
        return endDatetime;
      },

      cleanStartTime() {
        if (this.startTimeError) return;
        this.startTime = format(this.startDatetime, 'H:mm');
      },

      cleanEndTime() {
        if (this.endTimeError) return;
        this.endTime = format(this.endDatetime, 'H:mm');
      },

      get workedTime() {
        return this.duration.hours || 0;
      },

      isTimeAvailable(date) {
        const filter = this.allShifts.get(this.shiftId).timeFilter;
        if (filter === 'always') return true;
        if (filter === 'weekend' && this.info.isHoliday(date)) return true;
        if (filter === 'weekday' && !this.info.isHoliday(date)) return true;
        return false;
      },

      get isOvernight() {
        return this.startDatetime.getDate() !== this.endDatetime.getDate();
      },

      get shiftAttributions() {
        if (this.allAttributions.shiftDayStaff.has(this.shiftId)) {
          return this.allAttributions.shiftDayStaff.get(this.shiftId);
        }
        return observable.map();
      },

      staffsPlannedCount(day) {
        if (!this.shiftAttributions.has(day)) return 0;
        return this.shiftAttributions.get(day).size;
      },

      staffsPlanned(day) {
        if (!this.shiftAttributions.has(day)) return [];
        return [...this.shiftAttributions.get(day).keys()].map(staffId =>
          this.allStaffs.get(staffId)
        );
      },

      daySlots(day) {
        if (!this.isTimeAvailable(day)) return 0;
        return this.slots;
      },

      dayIsFull(day) {
        return this.staffsPlannedCount(day) >= this.daySlots(day);
      },

      clone() {
        const copy = shiftModel.extract(this.raw).value;

        const alreadyCopy = copy.description.match(/^(.+) \((\d+)\)/);

        if (alreadyCopy) {
          const copyNr = parseInt(alreadyCopy[2], 10) + 1;
          copy.description = `${alreadyCopy[1]} (${copyNr})`;
        } else {
          copy.description = `${copy.description} (1)`;
        }

        const newShiftId = this.allShifts.createShift();
        this.allShifts.update(newShiftId, copy);
        return newShiftId;
      },

      delete() {
        [...this.shiftAttributions.entries()].forEach(([day, staffs]) =>
          [...staffs.keys()].forEach(staffId =>
            this.allAttributions.deleteAttribution(day, staffId, this.shiftId)
          )
        );
        this.allShifts.deleteShift(this.shiftId);
      },

      toggleStaff(day, staff) {
        this.allAttributions.toggle(staff, day, this.shiftId);
      },

      staffWorks(day, staffId) {
        return this.allAttributions.exists(staffId, day, this.shiftId);
      },

      get hoursWorked() {
        if (!this.isOvernight) return [this.workedTime];

        const totalPresence = differenceInMinutes(this.endDatetime, this.startDatetime) / 60;
        const presenceRatio = this.workedTime / totalPresence;

        return [
          (differenceInMinutes(NEXT_DAY, this.startDatetime) / 60) * presenceRatio,
          (differenceInMinutes(this.endDatetime, NEXT_DAY) / 60) * presenceRatio,
        ];
      },
    });

    extendObservable(this, api, {
      setCategories: action.bound,
      setDuration: action.bound,
      delete: action.bound,
      toggleStaff: action.bound,
    });
  }
}

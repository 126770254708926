/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';

import './setup';
import './unsupported-check';

import App from './App.jsx';
import GlobalStyle from './GlobalStyle.jsx';

import { unregister } from './registerServiceWorker';

ReactDOM.render(
  <>
    <GlobalStyle />
    <App />
  </>,
  document.getElementById('root')
);
unregister();

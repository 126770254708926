import React from 'react';
import styled, { css } from 'styled-components/macro';

import { lighten, desaturate } from 'polished';
import { compose } from 'ramda';
import { color } from '../theme';

const baseColor = color();
const lighterColor = color(1);
const neutralLightColor = compose(
  desaturate(0.2),
  lighten(0.5),
  baseColor
);
const desaturatatedDarkColor = compose(
  desaturate(0.3),
  lighterColor
);

const setDisabled = ({ disabled }) => {
  if (!disabled) return null;
  return css`
    &,
    &:hover {
      cursor: not-allowed;
      background-color: ${desaturatatedDarkColor};
      border-color: ${desaturatatedDarkColor};
      color: white;
    }

    &:active {
      transform: initial;
    }
  `;
};

export const Button = styled.button.attrs({ tabIndex: 1 })`
  flex: 0 1 auto;
  color: #444;
  fill: #444;
  background-color: ${neutralLightColor};
  padding: 5px 10px 5px 10px;

  border: solid 1px ${neutralLightColor};
  border-radius: 2px;

  font-weight: lighter;

  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }

  &:hover {
    color: white;
    fill: white;
    font-weight: lighter;
    background-color: ${lighterColor};
    border-color: ${lighterColor};
  }

  &:focus {
    outline: none;
    border-color: ${lighterColor};
  }

  ${setDisabled};
`;

export const SideButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;

  border-left-color: ${lighterColor};
`;

export const ActionButton = styled(Button)`
  color: white;
  fill: white;
  background-color: ${baseColor};
  border-color: ${baseColor};

  &:hover {
    color: white;
    fill: white;
    background-color: ${color(3)};
    border-color: ${color(3)};
  }

  &:focus {
    border-color: ${color(3)};
  }
`;

export const CancelButton = styled(Button)`
  background-color: transparent;
  color: ${baseColor};
  fill: ${baseColor};

  border-color: transparent;

  &:hover {
    color: ${baseColor};
    fill: ${baseColor};

    background-color: #0000002b;
    border-color: transparent;
  }

  &:focus {
    border-color: #0000002b;
    border-width: 1px;
  }

  ${setDisabled};
`;

export const LinkButton = styled(Button.withComponent('a'))`
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

const BigSpan = styled.span`
  @media (max-width: ${props => props.breakPoint}) {
    display: none;
  }
`;

const WrapperSpan = styled.span`
  flex: 0 0 auto;
  & > :not(:first-child) {
    margin-left: 5px;
  }
`;

export const ReactiveButton = ({
  smallContent,
  bigContent,
  breakPoint = '1000px',
  ButtonComponent = Button,
  ...props
}) => (
  <ButtonComponent {...props}>
    <WrapperSpan>
      <span>{smallContent}</span>
      <BigSpan breakPoint={breakPoint}>{bigContent}</BigSpan>
    </WrapperSpan>
  </ButtonComponent>
);

export const TextButton = styled.button`
  flex: 0 0 auto;
  font-size: 0.8em;
  cursor: pointer;
  padding: 5px;
  color: ${baseColor};
  fill: ${baseColor};

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
  &:hover:not(:disabled) {
    color: ${lighterColor};
    fill: ${lighterColor};
  }
  background-color: transparent;
  border: none;
  border-radius: 2px;
`;

export const MenuButton = styled.button.attrs({ tabIndex: -1 })`
  border: none;
  background-color: #ecf0f1;
  border-radius: 2px;
  padding: 0px;

  fill: #444;

  width: 2em;
  height: 2em;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #ecf0f1;

  font-weight: lighter;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  &:disabled > * {
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    background-color: ${lighterColor};
    border-color: ${lighterColor};
    stroke-width: 2px;
    fill: white;
  }

  &:focus {
    outline: none;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  & > * {
    border-radius: 0px;
  }

  & > :first-child {
    border-radius: 2px 0px 0px 2px;
  }

  & > :last-child {
    border-radius: 0px 2px 2px 0px;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:not(:first-child),
  & > ${LinkButton}:not(:first-child),
  & > ${ButtonGroup}:not(first-child) {
    margin-left: 10px;
  }
`;

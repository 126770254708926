import { observable } from 'mobx';

export default observable({
  Grid: {
    en: 'Grid',
    fr: 'Grille',
  },

  Absences: {
    en: 'Absences',
    fr: 'Congés',
  },

  Edit: {
    en: 'Edit',
    fr: 'Edition',
  },
  Schedule: {
    en: 'Schedule',
    fr: 'Plan',
  },
  Calendar: {
    en: 'Calendar',
    fr: 'Calendrier',
  },
  'Full Calendar': {
    en: 'Full Calendar',
    fr: 'Calendrier complet',
  },
  Summary: {
    en: 'Summary',
    fr: 'Résumé',
  },

  Staffs: {
    en: 'Staffs',
    fr: 'Personel',
  },

  Staff: {
    en: 'Staff',
    fr: 'Personel',
  },

  Shifts: {
    en: 'Shifts',
    fr: 'Horaires',
  },

  Shift: {
    en: 'Shift',
    fr: 'Horaire',
  },

  Project: {
    en: 'Project',
    fr: 'Projet',
  },

  Share: {
    en: 'Share',
    fr: 'Partager',
  },

  History: {
    en: 'Version History',
    fr: 'Historique de version',
  },

  Print: {
    en: 'Print',
    fr: 'Imprimer',
  },

  'Change Project': {
    en: 'Change Project',
    fr: 'Changer de Projet',
  },

  Worked: {
    en: 'Worked',
    fr: 'Travaillé',
  },

  'Max Week': {
    en: 'Max Week',
    fr: 'Max Semaine',
  },

  'W-E+': {
    en: 'W-E+',
    fr: 'Fériés',
  },

  Nights: {
    en: 'Nights',
    fr: 'Nuits',
  },
  ' hours that week': {
    en: ' hours that week',
    fr: ' heures cette semaine',
  },
  ' night shifts': {
    en: ' night shifts',
    fr: ' horaires de nuit',
  },
  ' assigned (out of ': {
    en: ' assigned (out of ',
    fr: ' assignés (de ',
  },
  ' hours that day': {
    en: ' hours that day',
    fr: ' heures ce jour',
  },
  ' days on holidays': {
    en: ' days on holidays',
    fr: ' jours fériés',
  },
  ' days in a row': {
    en: ' days in a row',
    fr: ' jours à la suite',
  },
  Info: {
    en: 'Info',
    fr: 'Info',
  },
  Name: {
    en: 'Name',
    fr: 'Nom',
  },
  'Start Month': {
    en: 'First Month',
    fr: 'Premier mois',
  },
  'End Month': {
    en: 'Last Month',
    fr: 'Dernier mois',
  },
  Holidays: {
    en: 'Holidays',
    fr: 'Fériés',
  },
  'Delete Staff': {
    en: 'Delete Staff',
    fr: 'Supprimer Employé',
  },
  'New Staff': {
    en: 'New Staff',
    fr: 'Nouvel Employé',
  },
  'Full Name': {
    en: 'Full Name',
    fr: 'Nom Complet',
  },
  Shorthand: {
    en: 'Shorthand',
    fr: 'Abbréviation',
  },
  'Abbreviated name of the staff (typically initials)': {
    en: 'Abbreviated name of the staff (typically initials)',
    fr: 'Version courte du nom du membre du personel (initiales typiquement)',
  },
  'Activity Rate': {
    en: 'Activity Rate',
    fr: "Taux d'activité",
  },
  Group: {
    en: 'Group',
    fr: 'Groupe',
  },
  'Group of this staff belongs to. This is used for grouping and filtering.': {
    en: 'Group of this staff belongs to. This is used for grouping and filtering.',
    fr: 'Groupe dont ce membre du personel appartient. Utilisé pour grouper et filtrer.',
  },
  'Delete Shift': {
    en: 'Delete Shift',
    fr: 'Supprimer Horaire',
  },
  Description: {
    en: 'Description',
    fr: 'Description',
  },
  'Abbreviated name of the shift': {
    en: 'Abbreviated name of the shift',
    fr: "Version courte du nom de l'horaire",
  },
  Color: {
    en: 'Color',
    fr: 'Couleur',
  },
  'Color to represent this shift in grid mode': {
    en: 'Color to represent this shift in grid mode',
    fr: "Couleur repésentant l'horaire en mode grille.",
  },
  'Start and end times': {
    en: 'Start and end times',
    fr: 'Heure de début et fin',
  },
  'Time the member of staff is working': {
    en: 'Time the member of staff is working',
    fr: "Définition de l'horaire",
  },
  Duration: {
    en: 'Duration',
    fr: 'Durée',
  },
  'Amount of hours credited for this shift': {
    en: 'Amount of hours credited for this shift',
    fr: "Temps compatbilisé pour l'employé pour cet horaire",
  },
  Slots: {
    en: 'Slots',
    fr: 'Effectif',
  },
  'Number of people expected to fill that shift': {
    en: 'Number of people expected to fill that shift',
    fr: 'Nombre de personnes nécessaires à cet horaire',
  },
  'Time filter': {
    en: 'Time filter',
    fr: 'Filtres de temps',
  },
  'Type of days this shift is available': {
    en: 'Type of days this shift is available',
    fr: 'Type de jour pour lequel cet horaire est valable',
  },
  Categories: {
    en: 'Categories',
    fr: 'Catégories',
  },
  'Add categories to allow advanced filtering': {
    en: 'Add categories to allow advanced filtering',
    fr: 'Ajouter des categories pour permettre un filtrage avancé',
  },
  Cancel: {
    en: 'Cancel',
    fr: 'Annuler',
  },
  'Create new Shift': {
    en: 'Create new Shift',
    fr: 'Nouvel horaire',
  },
  Save: {
    en: 'Save',
    fr: 'Sauver',
  },
  'Staff Filters': {
    en: 'Staff Filters',
    fr: 'Filtres du personel',
  },
  'Shift Filters': {
    en: 'Shift Filters',
    fr: 'Filtres des horaires',
  },
  Date: {
    en: 'Date',
    fr: 'Date',
  },
  Publication: {
    en: 'Publication',
    fr: 'Publication',
  },
  'Planning changes': {
    en: 'Planning changes',
    fr: 'Changements du plan',
  },
  'New Shift': {
    en: 'New Shift',
    fr: 'Nouvel Horaire',
  },
  'Are you sure you want to delete ': {
    en: 'Are you sure you want to delete ',
    fr: 'Êtes vous sur de vouloir supprimer ',
  },
  Email: {
    en: 'Email',
    fr: 'Email',
  },
  'Create new absence': {
    en: 'Create new absence',
    fr: 'Créer un nouveau congé',
  },
  Type: {
    en: 'Type',
    fr: 'Type',
  },
  Comment: {
    en: 'Comment',
    fr: 'Commentaire',
  },
  Total: {
    en: 'Total',
    fr: 'Total',
  },
  Filters: {
    en: 'Filters',
    fr: 'Filtres',
  },
  'Loading...': {
    en: 'Loading...',
    fr: 'Chargement...',
  },
  'Last publication ': {
    en: 'Last publication ',
    fr: 'Dernière publication ',
  },
  'Changes since last publication': {
    en: 'Changes since last publication',
    fr: 'Changements depuis la dernière publication',
  },
  Title: {
    en: 'Title',
    fr: 'Titre',
  },
  'Limit Range': {
    en: 'Limit the range',
    fr: 'Limiter la période',
  },
  'Limit the range to print': {
    en: 'Limit the range of shifts',
    fr: 'Limiter les horaires à la période',
  },
  'Colored Print': {
    en: 'Colored Print',
    fr: 'Impression avec couleurs',
  },
  'Use the shift color': {
    en: 'Use the shift color',
    fr: "Utiliser la couleur de l'horaire",
  },
  'Apply a lighter color for absences': {
    en: 'Apply a lighter color for absences',
    fr: 'Appliquer une couleur plus claire pour les congés',
  },
  'Hide absence comments': {
    en: 'Hide absence comments',
    fr: 'Cacher les commentaires de congés',
  },
  'Publishing...': {
    en: 'Publishing...',
    fr: 'Publication en cours...',
  },
  Publish: {
    en: 'Publish',
    fr: 'Publier',
  },
  'No changes': {
    en: 'No changes',
    fr: 'Pas de changement',
  },
  Compare: {
    en: 'Compare',
    fr: 'Comparer',
  },
  'Published ': {
    en: 'Published ',
    fr: 'Publié ',
  },
  'Compared with version published ': {
    en: 'Compared with version published ',
    fr: 'Comparaison avec la version publiée ',
  },
  'Full History': {
    en: 'Full History',
    fr: 'Historique Complet',
  },
  'No additional history': {
    en: 'No additional history',
    fr: "Pas d'historique supplémentaire",
  },
  Overview: {
    en: 'Overview',
    fr: "Vue d'ensemble",
  },
  Copy: {
    en: 'Copy',
    fr: 'Copier',
  },
  'Occupation: ': {
    en: 'Occupation: ',
    fr: 'Occupation: ',
  },
  'See in context': {
    en: 'See in context',
    fr: 'Voir en contexte',
  },
  WANT_TO_LOG_OUT: {
    en: 'You are currently logged in in your account. Are you sure you want to log out?',
    fr: 'Vous êtes connecté avec votre compte. Êtes vous sûr de vouloir vous déconnecter?',
  },
  'Log Out': {
    en: 'Log Out',
    fr: 'Déconnection',
  },
  'Log In': {
    en: 'Log In',
    fr: 'Connection',
  },
  'Welcome to Horair.es!': {
    en: 'Welcome to Horair.es!',
    fr: 'Bienvenue sur Horair.es!',
  },
  HORAIRES_SHORT_SUMMARY: {
    en: 'This tool will help you create and maintain a 24/7 schedule for small to medium teams.',
    fr:
      'Cet outil va vous permettre de créer et de maintenir un planning 24 heures sur 24 7 jours sur 7 pour votre petite à moyenne équipe.',
  },
  LOGIN_MESSAGE: {
    en:
      'To login you just need to request your one use password. Enter your email adress and it will be sent to you.',
    fr:
      "Pour vous connecter, il vous suffit d'entrer votre mot de passe à usage unique. Entrez votre adresse email pour recevoir un nouveau mot de passe.",
  },
  'Single use password sent': {
    en: 'Single use password sent',
    fr: 'Mot de passe à usage unique envoyé',
  },
  Request: {
    en: 'Request',
    fr: 'Envoyer',
  },
  'Connecting...': {
    en: 'Connecting...',
    fr: 'Connection...',
  },
  'You are offline': {
    en: 'You are offline',
    fr: 'Vous êtes hors ligne',
  },
  'This tool only works if you are online.': {
    en: 'This tool only works if you are online.',
    fr: 'Cet outil ne fonctionne que lorsque vous êtes en ligne',
  },
  'Reconnect to be able to use edit your Horair.es.': {
    en: 'Reconnect to be able to use edit your Horair.es.',
    fr: 'Reconnectez vous pour pouvoir éditer vos Horair.es',
  },
  'New Project': {
    en: 'New Project',
    fr: 'Nouveau Projet',
  },
  Create: {
    en: 'Create',
    fr: 'Créer',
  },
  'Creating project...': {
    en: 'Creating project...',
    fr: 'Projet en création...',
  },
  'Delete Project': {
    en: 'Delete Project',
    fr: 'Supprimer Projet',
  },
  Delete: {
    en: 'Delete',
    fr: 'Supprimer',
  },
  Open: {
    en: 'Open',
    fr: 'Ouvrir',
  },
  'You have no staff and no shift defined.': {
    en: 'You have no staff and no shift defined.',
    fr: "Il n'y a aucun horaire ou membre du personel défini.",
  },
  'You have no staff defined.': {
    en: 'You have no staff defined.',
    fr: "Il n'y a aucun membre du personel défini.",
  },
  'You have no shift defined.': {
    en: 'You have no shift defined.',
    fr: "Vous n'avez pas d'horaire défini.",
  },
  'No publication yet': {
    en: 'No publication yet',
    fr: 'Aucune publication',
  },
  'You do not have a publication yet.': {
    en: 'You do not have a publication yet.',
    fr: 'Aucune publication',
  },
  'You must define a description': {
    en: 'You must define a description',
    fr: 'Vous devez définir une description',
  },
  'You must define a full name': {
    en: 'You must define a full name',
    fr: 'Vous devez définir un nom complet',
  },
  'You must define a shorthand': {
    en: 'You must define a shorthand',
    fr: 'Vous devez définir une abbréviation',
  },
  'You must define a start time': {
    en: 'You must define a start time',
    fr: 'Vous devez définir une heure de début',
  },
  'You must define a end time': {
    en: 'You must define a end time',
    fr: 'Vous devez définir une heure de fin',
  },
  'Invalid start time': {
    en: 'Invalid start time',
    fr: 'Heure de début non valide',
  },
  'Invalid end time': {
    en: 'Invalid end time',
    fr: 'Heure de fin non valide',
  },
  hours: {
    en: 'hours',
    fr: 'heures',
  },
  months: {
    en: 'months',
    fr: 'mois',
  },
  'last month': {
    en: 'last month',
    fr: 'dernier mois',
  },
  days: {
    en: 'days',
    fr: 'jours',
  },
  'Select...': {
    en: 'Select...',
    fr: 'Choisir...',
  },
  'No result found...': {
    en: 'No result found...',
    fr: 'Pas de résultat...',
  },
  'Create new category': {
    en: 'Create new category',
    fr: 'Créer une nouvelle catégorie',
  },
  'Create new group': {
    en: 'Create new group',
    fr: 'Créer un nouveau groupe',
  },
  'Every day': {
    en: 'Every day',
    fr: 'Tous les jours',
  },
  'Week days': {
    en: 'Week days',
    fr: 'Jours de semaine',
  },
  'Weekend and holidays': {
    en: 'Weekend and holidays',
    fr: 'Weekend et fériés',
  },
  Vacation: {
    en: 'Vacation',
    fr: 'Vacances',
  },
  Formation: {
    en: 'Formation',
    fr: 'Formation',
  },
  'Other absence': {
    en: 'Other absence',
    fr: 'Autre congé',
  },
  Absent: {
    en: 'Absent',
    fr: 'Absent',
  },
  Language: {
    en: 'Language',
    fr: 'Langue',
  },
  'Choose the main UI language': {
    en: 'Choose the main UI language',
    fr: "Choisissez la langue principale de l'interface utilisateur",
  },
  'Region in order to have automatic bank holidays': {
    en: 'Region in order to have automatic bank holidays',
    fr: "Région pour laquelle les jours fériés automatiques s'appliquent",
  },
  'Your filters correspond to no data.': {
    en: 'Your filters correspond to no data.',
    fr: 'Vos filtres ne correspondent à aucune donnée',
  },
  'Staff members have not been emailed yet.': {
    en: 'Staff members have not been emailed yet.',
    fr: "Vous n'avez pas encore notifié les employés.",
  },
  'Send Emails': {
    en: 'Send Emails',
    fr: 'Envoyer des Emails',
  },
  'Emails were sent ': {
    en: 'Emails were sent ',
    fr: 'Les emails ont été envoyés ',
  },
  'There has not been any new publication since.': {
    en: 'There has not been any new publication since.',
    fr: "Il n'y a pas eu de publication depuis.",
  },
  'You have published new planning versions since.': {
    en: 'You have published new planning versions since.',
    fr: 'Vous avez publié des nouveaux horaires depuis.',
  },
  'Are you sure you want to send emails to ': {
    en: 'Are you sure you want to send emails to ',
    fr: 'Êtes vous sur de voloir envoyer des emails à ',
  },
  ' have invalid or missing emails and will not be notified.': {
    en: ' have invalid or missing emails and will not be notified.',
    fr: ' ont des emails manquants ou invalides et ne vont pas être notifiés.',
  },
  'You can share these links directly.': {
    en: 'You can share these links directly.',
    fr: 'Vous pouvez partager ces liens directement.',
  },
  'Standard Day': {
    en: 'Standard Day',
    fr: 'Journée standard',
  },
  'Amount of time of a typical work day. Duration that is generally accounted for an absence.': {
    en:
      'Duration of a typical work day. Amount of time that is generally accounted for an absence.',
    fr: "Durée d'une journée de travail typique. Généralement temps comptabilisée pour un congé.",
  },
  'Total in a row': {
    en: 'Total in a row',
    fr: 'Total conscécutifs',
  },
  'Single Day': {
    en: 'Single Day',
    fr: 'Un jour',
  },
  'Two Days': {
    en: 'Two Days',
    fr: 'Deux jours',
  },
  'Until next Friday': {
    en: 'Until next Friday',
    fr: "Jusqu'au vendredi suivant",
  },
  'Until next Sunday': {
    en: 'Until next Sunday',
    fr: "Jusqu'au dimanche suivant",
  },
  Weekends: {
    en: 'Weekends',
    fr: 'Weekends',
  },
  'Vacation Days': {
    en: 'Vacation Days',
    fr: 'Jours de vacances',
  },
  'vacation days taken': {
    en: 'vacation days taken',
    fr: 'jours de vacances pris',
  },
  'out of': {
    en: 'out of',
    fr: 'de',
  },
  'weekend days reserved': {
    en: 'weekend days reserved',
    fr: 'jours de weekend réservés',
  },
  'formation days': {
    en: 'formation days',
    fr: 'jours de formation',
  },
  'other absence days': {
    en: 'other absence days',
    fr: 'autres jours de congé',
  },
  'No more planned shifts': {
    en: 'No more planned shifts',
    fr: "Plus d'horaires planifié",
  },
  'Full Overview': {
    en: 'Full Overview',
    fr: "Vue d'ensemble complète",
  },
  'Full Schedule': {
    en: 'Full Schedule',
    fr: 'Plan complet',
  },
  'You do not have a shift yet.': {
    en: 'You do not have a shift yet',
    fr: "Vous n'avez pas encore défini d'horaire",
  },
  'You do not have a staff yet.': {
    en: 'You do not have a staff yet',
    fr: "Vous n'avez pas encore défini de membre du personel",
  },
  Invite: {
    en: 'Invite',
    fr: 'Inviter',
  },
  Permissions: {
    en: 'Permissions',
    fr: 'Permissions',
  },
  'The owner of these email adresses can edit this planning': {
    en: 'The owners of these email adresses can edit this planning',
    fr: 'Les propriétaires de ces emails peuvent editer ce planning.',
  },
  remove: {
    en: 'remove',
    fr: 'retirer',
  },
  'Are you sure you want to remove the access to this projet?': {
    en: 'Are you sure you want to remove the access to this projet?',
    fr: "Êtes vous sûr de vouloir retirer l'accès à ce projet?",
  },
  'Remove Access': {
    en: 'Remove Access',
    fr: "Retirer l'accès",
  },
  'Import from another project': {
    en: 'Import from another project',
    fr: "Importer d'un autre projet",
  },
  Import: {
    en: 'Import',
    fr: 'Importer',
  },
  'Choose a project to import from': {
    en: 'Choose a project to import from',
    fr: "Choisir le projet pour l'import",
  },

  'Your planned absences': {
    en: 'Your planned absences',
    fr: 'Vos congés planifiés',
  },
  'Absences calendar': {
    en: 'Absences calendar',
    fr: 'Calendrier des congés',
  },
  'Week of': {
    en: 'Week of',
    fr: 'Semaine du',
  },
  'No more planned absence': {
    en: 'No more planned absence',
    fr: 'Plus de congés planifiés',
  },
  'unpaid free days': {
    en: 'unpaid free days',
    fr: 'jours de congés non payé',
  },
  'Unpaid days': {
    en: 'Unpaid absence',
    fr: 'Congé non\u00A0payé',
  },
  'Wrong password': {
    en: 'Wrong password',
    fr: 'Mot de passe non valide',
  },
  'Wrong email': {
    en: 'Wrong email',
    fr: 'Pas de compte pour cet email',
  },
  'New Account': {
    en: 'New Account',
    fr: 'Nouveau compte',
  },
  Register: {
    en: 'Register',
    fr: 'Créer',
  },
  'User already exists': {
    en: 'User already exists',
    fr: "L'utilisateur existe déjà",
  },
  'No account yet?': {
    en: 'Not registered yet?',
    fr: 'Pas encore enregistré?',
  },
  'Create a new account.': {
    en: 'Create a new account.',
    fr: 'Créer un nouveau compte.',
  },
  'Your registration code has been sent.': {
    en: 'Your registration code has been sent.',
    fr: "Votre code d'enregistrement a été envoyé.",
  },

  'Calendar Apps': {
    en: 'Calendar Apps',
    fr: 'Applications Calendrier',
  },

  CALENDAR_INFO: {
    en: 'You can import this information in your calendar in Outlook or Apple Calendar.',
    fr:
      'Vous pouvez importer les information dans votre application calendrier, par exemple Outlook ou le Calendrier Apple.',
  },

  'Open in Calendar': {
    en: 'Open in calendar',
    fr: 'Ouvrir dans le calendrier',
  },

  MANUAL_CALENDAR_INFO: {
    en: 'You can also enter manually the web calendar adress in your calendar app if necessary.',
    fr:
      "Si nécessaire, il est possible d'entrer l'adresse du calendrier web dans votre application.",
  },

  'No filter': {
    en: 'No filter',
    fr: 'Pas de filtre',
  },
  'Additional text to email': {
    en: 'Additional text to email (optional)',
    fr: "Texte à ajouter à la fin de l'email (optionel)",
  },

  'Publish the whole period': {
    en: 'Publish the whole period',
    fr: 'Publier pour toute la durée du projet',
  },
  'Add limit': {
    en: 'Add limit',
    fr: 'Limiter la publication',
  },
  'Publish a partial planning': {
    en: 'Publish a partial planning',
    fr: 'Publier un plan partiel',
  },
  'Remove limit': {
    en: 'Remove limit',
    fr: 'Enlever la limite',
  },
  'Holidays only': {
    en: 'Absences only',
    fr: 'Congés uniquement',
  },
  Until: {
    en: 'Until',
    fr: "Jusqu'en",
  },
  'Nothing planned': {
    en: 'Nothing planned',
    fr: "Pas d'horaire planifié",
  },

  PUBLICATION_PERIOD_NODE: {
    en: 'Note: absences over the full period will be included',
    fr: 'Note: les congés pour la période complète seront inclus',
  },
  'business days': {
    en: 'business days',
    fr: 'jours ouvrables',
  },

  'Previous project shifts': {
    en: 'Previous project shifts',
    fr: 'Horaires du projet précédent',
  },
  'Previous Project': {
    en: 'Previous Project',
    fr: 'Projet Précédent',
  },
  'Show the end of this project for more context': {
    en: 'Show the end of this project for more context.',
    fr: 'Permet de voir la fin du projet précédent pour plus de contexte.',
  },
  'You do not have the permission to access the previous project.': {
    en: 'You do not have the permission to access the previous project.',
    fr: "Vous n'avez pas la permission d'accéder au projet précédent.",
  },
  Hide: {
    en: 'Hide',
    fr: 'Cacher',
  },
  'Additional Information': {
    en: 'Additional Information',
    fr: 'Information complémentaire',
  },
  'Information to show inline while editing.': {
    en: 'Information to show inline while editing.',
    fr: "Information affichée lors de l'édition.",
  },
  'Arrival month': {
    en: 'Arrival month',
    fr: "Mois d'entrée",
  },
  'First active month of the staff, if their arrival happens during the project duration.': {
    en: 'First active month of the staff, if their arrival happens during the project duration.',
    fr:
      'Premier mois actif du membre du personel, si leur entrée se passe dans le courant du projet.',
  },
  'Departure month': {
    en: 'Departure month',
    fr: 'Mois de sortie',
  },
  'Last active month of the staff, if their departure happens during the project duration.': {
    en: 'Last active month of the staff, if their departure happens during the project duration.',
    fr:
      'Dernier mois actif du membre du personel, si leur départ se passe dans le courant du projet.',
  },
  Confirmation: {
    en: 'Confirmation',
    fr: 'Confirmation',
  },
  'Change the date': {
    en: 'Change the date',
    fr: 'Changer la date',
  },
  'Current Team': {
    en: 'Current Team',
    fr: 'Équipe actuelle',
  },
  'Copy a serie of shifts': {
    en: 'Copy a serie of shifts',
    fr: "Copier une série d'horaires",
  },
  'One week': {
    en: 'One week',
    fr: 'Une semaine',
  },
  'Remove a serie of shifts': {
    en: 'Remove a serie of shifts',
    fr: "Enlever une série d'horaire",
  },
  'Two weeks': {
    en: 'Two weeks',
    fr: 'Deux semaines',
  },
  'Three weeks': {
    en: 'Three weeks',
    fr: 'Trois semaines',
  },
  'Four weeks': {
    en: 'Four weeks',
    fr: 'Quatre semaines',
  },
  Custom: {
    en: 'Custom',
    fr: 'Autre',
  },
  'No shift selected': {
    en: 'No shift selected',
    fr: "Pas d'horaire sélectionné",
  },
  SCHEDULE_CHANGED: {
    en: 'The schedule has changed since you last opened it',
    fr: 'Le planning a changé depuis votre dernier accès.',
  },
  'Additional holidays': {
    en: 'Additional holidays',
    fr: 'Fériés additionels',
  },
  'Not a holiday': {
    en: 'Not a holiday',
    fr: 'Pas un jour férié',
  },
  'Customise holidays': {
    en: 'Customise holidays',
    fr: 'Personaliser les fériés',
  },
  'Previous month': {
    en: 'Previous month',
    fr: 'Mois précédent',
  },
  'Next month': {
    en: 'Next month',
    fr: 'Mois suivant',
  },
  Close: {
    en: 'Close',
    fr: 'Fermer',
  },
  'Custom Period': {
    en: 'Custom Period',
    fr: 'Autre Période',
  },

  'Clone this shift': {
    en: 'Clone this shift',
    fr: 'Cloner cet horaire',
  },
  'Info Layers': {
    en: 'Highlight',
    fr: 'Mettre en évidence',
  },
  'Show Changes': {
    en: 'Changes',
    fr: 'Changements',
  },
  'in the version currently published.': {
    en: 'in the version currently published.',
    fr: 'dans la version publiée actuellement.',
  },
  'There was an error saving your data.': {
    en: 'There was an error saving your data.',
    fr: 'Une erreur de sauvergarde a été détectée.',
  },
  'If you do not reload this page you might encounter some data loss.': {
    en: 'If you do not reload this page you might encounter some data loss.',
    fr: 'Nous vous conseillons de recharger cette page pour ne pas perdre de données.',
  },
  Reload: {
    en: 'Reload',
    fr: 'Recharger',
  },
  Ignore: {
    en: 'Ignore',
    fr: 'Ignorer',
  },
  'You use an old browser': {
    en: 'You use an old browser',
    fr: 'Vous utilisez un navigateur obsolète',
  },
  'This website has been build for more recent browser.': {
    en: 'This website has been build for more recent browser.',
    fr: 'Ce site a été optimisé pour des navigateurs plus récent',
  },
  'You can try using Horair.es, but you will have a better experience with a more recent browser.': {
    en:
      'You can try using Horair.es, but you will have a better experience with a more recent browser.',
    fr:
      "Vous pouvez néanmoins essayer d'utiliser Horair.es, mais votre expérience sera meilleure avec un navigateur plus récent.",
  },
  'More Info': {
    en: 'More Info',
    fr: "Plus d'information",
  },
});

import { observable, action, observe, reaction, toJS } from 'mobx';
import entries from 'lodash/entries';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { v4 as uuid } from 'uuid';
import { rgb, parseToRgb } from 'polished';

import { sorted } from 'itertools';

import { parseUnixTime } from '../dates';
import theme from '../../theme';

import shiftModel from '../models/shift';

export const TIME_FILTERS = ['always', 'weekday', 'weekend'];

export default () =>
  observable(
    {
      raw: observable.map(),

      init(project) {
        this.project = project;
        return this.project
          .fetchValues('shift')
          .then(keyValues => keyValues.forEach(([key, value]) => this.set(key, value)));
      },

      startObserve() {
        const onChange = this.project.onChangeHandler.bind(this.project, 'shift');
        this._observer = observe(this.raw, onChange);
      },

      load(shifts) {
        shifts.forEach(({ key, value }) => this.set(key, value));
      },

      import(shifts) {
        shifts.forEach(({ value }) => {
          const key = uuid();
          this.set(key, value);
        });
      },

      dump() {
        return [...this.raw.entries()].map(([key, value]) => ({ key, value: toJS(value) }));
      },

      createShift() {
        const newId = uuid();
        this.set(newId, {
          description: '',
          shorthand: '',
          startTime: '',
          endTime: '',
          slots: 1,
          timeFilter: 'always',
          color: theme.palette.primary[0],
          duration: { hours: 1 },
          categories: {},
        });
        return newId;
      },

      deleteShift(shiftId) {
        this.raw.delete(shiftId);
      },

      set(
        key,
        {
          description,
          shorthand,
          startTime,
          endTime,
          duration = { hours: 1 },
          color = rgb(parseToRgb(theme.palette.primary[0])),
          timeFilter = 'always',
          slots = 1,
          categories = {},
        }
      ) {
        const shift = observable({
          id: key,
          description,
          shorthand,
          startTime,
          endTime,
          duration: observable(duration),
          color,
          slots,
          timeFilter,
          categories: observable.map(categories),
        });
        if (this.project) {
          reaction(
            () => shiftModel.extract(shift),
            ({ key: key_, value }) => this.project.update('shift', key_, value),
            { delay: 1000 }
          );
        }
        this.raw.set(key, shift);
      },

      get defaultShiftId() {
        if (!this.raw.size) return '';
        return this.all[0].id;
      },

      get(shiftId) {
        return this.raw.get(shiftId);
      },

      has(shiftId) {
        return this.raw.has(shiftId);
      },

      get all() {
        return sortBy([...this.raw.values()], val => [
          [...sorted(val.categories.keys())],
          val.timeFilter,
          parseUnixTime(val.startTime),
          val.shorthand,
        ]);
      },

      update(key, props) {
        if (!this.raw.has(key)) {
          this.set(key, props);
          return;
        }
        shiftModel.update(this.raw.get(key), props);
      },

      updates(changes) {
        if (changes.deletions) {
          changes.deletions.forEach(id => {
            if (this.raw.has(id)) this.raw.delete(id);
          });
        }

        if (changes.updates) {
          entries(changes.updates)
            .filter(([, update]) => !isEmpty(update))
            .forEach(([key, update]) => this.update(key, update));
        }
      },
    },
    {
      init: action.bound,
      load: action.bound,
      import: action.bound,
      createShift: action.bound,
      deleteShift: action.bound,
      set: action.bound,
      updates: action.bound,
    }
  );

import { observable, action } from 'mobx';
import findIndex from 'lodash/findIndex';
import defaultTo from 'lodash/defaultTo';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import { dayToWeek } from './dates';

const loadFromStorage = (key, defaultVal) =>
  JSON.parse(defaultTo(window.sessionStorage.getItem(key), defaultVal));

const loadDenseFromStorage = () => {
  let value = loadFromStorage('denseMode', '"big"');
  if (value === true) value = 'small';
  if (value === false) value = 'big';
  return value;
};

const loadMappedFromStorage = (key, whiteList) =>
  loadFromStorage(key, '[]')
    .filter(k => whiteList.includes(k))
    .map(k => [k, true]);

export default (staffsStore, shiftsStore, views) =>
  observable(
    {
      _selectedStaffId: null,
      _selectedShiftId: null,

      defaultStaffId: null,
      defaultShiftId: null,

      ready: false,
      overviewMode: false,

      missingPreviousProject: false,

      enableFilters: true,
      shiftFilter: observable.map({}),
      staffFilter: observable.map({}),
      timeFilter: loadFromStorage('timeFilter', 'true'),
      currentTeamFilter: loadFromStorage('currentTeamFilter', 'true'),

      enableChangesLayer: false,

      shiftsCopyStack: [],
      shownStaff: [],

      denseMode: loadDenseFromStorage(),

      endLoad() {
        this.ready = true;

        // This forces the default value to avoid jumping
        this.defaultStaffId = this.selectedStaffId;
        this.defaultShiftId = this.selectedShiftId;

        this.shiftFilter = this.shiftFilter.replace(
          loadMappedFromStorage('shiftFilter', views.shiftCategories)
        );
        this.staffFilter = this.staffFilter.replace(
          loadMappedFromStorage('staffFilter', views.staffGroups)
        );
      },

      enableOverviewMode() {
        this.overviewMode = true;
      },

      get filters() {
        const allFilters = new Map(views.categories.map(categ => [categ, new Map()]));
        views.staffCategories.forEach(category => {
          allFilters.get(category).set('staff', this.staffFilter.has(category));
        });
        views.shiftCategories.forEach(category => {
          allFilters.get(category).set('shift', this.shiftFilter.has(category));
        });

        return allFilters;
      },

      setStaffFilter(newFilters) {
        [...this.staffFilter.keys()].forEach(oldFilter => {
          this.staffFilter.delete(oldFilter);
        });
        newFilters.forEach(newFilter => {
          this.staffFilter.set(newFilter, true);
        });
      },

      toggleTimeFilter() {
        this.timeFilter = !this.timeFilter;
        window.sessionStorage.setItem('timeFilter', JSON.stringify(this.timeFilter));
      },

      toggleChangesLayer() {
        this.enableChangesLayer = !this.enableChangesLayer;
      },

      copyShifts(newShifts) {
        this.shiftsCopyStack = [newShifts];
      },

      toggleCurrentTeamFilter() {
        this.currentTeamFilter = !this.currentTeamFilter;
        window.sessionStorage.setItem('currentTeamFilter', JSON.stringify(this.currentTeamFilter));
      },

      toggleFilterStaff(category) {
        if (this.staffFilter.has(category)) {
          this.staffFilter.delete(category);
        } else {
          this.staffFilter.set(category, true);
        }
        window.sessionStorage.setItem('staffFilter', JSON.stringify([...this.staffFilter.keys()]));
      },

      toggleFilterShift(category) {
        if (this.shiftFilter.has(category)) {
          this.shiftFilter.delete(category);
        } else {
          this.shiftFilter.set(category, true);
        }
        window.sessionStorage.setItem('shiftFilter', JSON.stringify([...this.shiftFilter.keys()]));
      },

      toggleDensity() {
        if (this.denseMode === 'big') {
          this.denseMode = 'small';
        } else if (this.denseMode === 'small') {
          this.denseMode = 'tiny';
        } else if (this.denseMode === 'tiny') {
          this.denseMode = 'big';
        } else {
          this.denseMode = 'big';
        }
        window.sessionStorage.setItem('denseMode', JSON.stringify(this.denseMode));
      },

      get filteredShifts() {
        if (!this.enableFilters) {
          return views.shifts;
        }
        return views.shifts.filter(
          shift => ![...shift.categories.keys()].find(category => this.shiftFilter.has(category))
        );
      },

      get filteredWeekShifts() {
        return this.filteredShifts.filter(
          shift => shift.timeFilter === 'always' || shift.timeFilter === 'weekday'
        );
      },

      get filteredHolidaysShifts() {
        return this.filteredShifts.filter(
          shift => shift.timeFilter === 'always' || shift.timeFilter === 'weekend'
        );
      },

      filteredShiftsByDate(date) {
        if (!this.timeFilter) return this.filteredShifts;
        if (views.calendar.isHoliday(date)) {
          return this.filteredHolidaysShifts;
        }
        return this.filteredWeekShifts;
      },

      get filteredStaffs() {
        if (!this.enableFilters) {
          return views.staffs;
        }
        return views.staffs.filter(staff => !this.staffFilter.has(staff.group));
      },

      get filteredAbsenceComments() {
        return sortBy(
          uniq(
            this.filteredStaffs.flatMap(staff =>
              staff.absences
                .map(absence => absence.comment && absence.comment.trim())
                .filter(comment => comment)
            )
          )
        );
      },

      filteredStaffsForWeek(weekId) {
        if (!weekId) return this.filteredStaffs;
        if (!this.currentTeamFilter) return this.filteredStaffs;
        return this.filteredStaffs.filter(
          staff => weekId >= staff.firstWeek && weekId <= staff.lastWeek
        );
      },

      filteredStaffsForWeekByDay(date) {
        if (!date) return this.filteredStaffs;

        const weekId = dayToWeek(date);
        return this.filteredStaffsForWeek(weekId);
      },

      get hasData() {
        return this.filteredStaffs.length && this.filteredShifts.length;
      },

      get selectedStaffId() {
        if (this._selectedStaffId && staffsStore.has(this._selectedStaffId))
          return this._selectedStaffId;

        if (this.defaultStaffId && staffsStore.has(this.defaultStaffId)) return this.defaultStaffId;

        if (this.filteredStaffs.length) return this.filteredStaffs[0].staffId;
        if (!views.staffs.length) return null;
        return views.staffs[0].staffId;
      },

      set selectedStaffId(value) {
        this._selectedStaffId = value;
      },

      get selectedShiftId() {
        if (this._selectedShiftId && shiftsStore.has(this._selectedShiftId))
          return this._selectedShiftId;

        if (this.defaultShiftId && shiftsStore.has(this.defaultShiftId)) return this.defaultShiftId;

        if (this.filteredShifts.length) return this.filteredShifts[0].shiftId;
        if (!views.shifts.length) return null;
        return views.shifts[0].shiftId;
      },

      set selectedShiftId(value) {
        this._selectedShiftId = value;
      },

      selectedDay: null,

      focusedWeek: null,
      menuPos: { x: 0, y: 0 },

      changeMenuPos(event, d) {
        this.menuPos.x = d.lastX;
        this.menuPos.y = d.lastY;
      },

      selectedWeek: observable.map({}),

      get selectedStaff() {
        if (!this.selectedStaffId) return null;
        return views.staff(this.selectedStaffId);
      },

      get selectedShift() {
        if (!this.selectedShiftId) return null;
        return views.shift(this.selectedShiftId);
      },

      toggleCurrent(day) {
        this.selectedStaff.toggleShift(day, this.selectedShiftId);
      },

      selectStaff(staffId) {
        this.selectedStaffId = staffId;
      },

      selectNextStaff() {
        const currentIndex = findIndex(
          this.filteredStaffs,
          staff => staff.staffId === this.selectedStaffId
        );
        const nextStaff = this.filteredStaffs[
          (currentIndex + 1 + this.filteredStaffs.length) % this.filteredStaffs.length
        ];
        return this.selectStaff(nextStaff.staffId);
      },

      selectPreviousStaff() {
        const currentIndex = findIndex(
          this.filteredStaffs,
          staff => staff.staffId === this.selectedStaffId
        );
        const previousStaff = this.filteredStaffs[
          (currentIndex - 1 + this.filteredStaffs.length) % this.filteredStaffs.length
        ];
        return this.selectStaff(previousStaff.staffId);
      },

      selectShift(shiftId) {
        this.selectedShiftId = shiftId;
      },

      selectNextShift() {
        const currentIndex = findIndex(
          this.filteredShifts,
          shift => shift.shiftId === this.selectedShiftId
        );
        const nextShift = this.filteredShifts[
          (currentIndex + 1 + this.filteredShifts.length) % this.filteredShifts.length
        ];
        return this.selectShift(nextShift.shiftId);
      },

      selectPreviousShift() {
        const currentIndex = findIndex(
          this.filteredShifts,
          shift => shift.shiftId === this.selectedShiftId
        );
        const previousShift = this.filteredShifts[
          (currentIndex - 1 + this.filteredShifts.length) % this.filteredShifts.length
        ];
        return this.selectShift(previousShift.shiftId);
      },

      focusWeek(yearWeek) {
        this.focusedWeek = yearWeek;

        setTimeout(() => {
          if (this.focusedWeek === yearWeek) {
            this.focusedWeek = null;
          }
        }, 1000);
      },

      selectWeek(yearWeek) {
        this.selectedWeek.set(yearWeek, true);
      },

      deselectWeek(yearWeek) {
        this.selectedWeek.set(yearWeek, false);
      },

      toggleShowStaff(staffId) {
        if (this.shownStaff.includes(staffId)) {
          this.shownStaff.remove(staffId);
        } else {
          this.shownStaff.push(staffId);
        }
      },

      setEnableFilters(newValue) {
        this.enableFilters = newValue;
      },
    },
    {
      setStaffFilter: action.bound,
      toggleTimeFilter: action.bound,
      toggleCurrentTeamFilter: action.bound,
      toggleChangesLayer: action.bound,
      changeMenuPos: action.bound,
      toggleCurrent: action.bound,
      toggleDensity: action.bound,
      selectStaff: action.bound,
      selectNextStaff: action.bound,
      selectPreviousStaff: action.bound,
      selectShift: action.bound,
      selectNextShift: action.bound,
      selectPreviousShift: action.bound,
      toggleShowStaff: action.bound,
      focusWeek: action.bound,
      selectWeek: action.bound,
      deselectWeek: action.bound,
      endLoad: action.bound,
      setEnableFilters: action.bound,
    }
  );

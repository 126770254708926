import React, { useRef } from 'react';

import styled from 'styled-components/macro';
import { darken } from 'polished';
import { color } from '../theme';
import WhenModeIs from '../ui-base/WhenModeIs.jsx';

import WhenCurrentProject from '../ui-base/WhenCurrentProject.jsx';
import useOutsideCallback from '../quarolib/hooks/useOutsideCallback';

import {
  EditorNav,
  DetailsEditorNav,
  SettingsEditorNav,
  ViewerNav,
  NameViewerNav,
  DetailsViewerNav,
  IntegrationViewerNav,
  GlobalNav,
  LanguageNav,
  AdminNav,
} from '../ui-base/MainNavigation.jsx';

const Separator = styled.div`
  width: 100%;
  flex: 0 0 auto;
  margin-top: 5px;
  margin-bottom: 15px;
  height: 0;
  border-bottom: solid 1px ${darken(0.2, 'white')};
  content: '';
`;

const SlidingMenuWrapper = styled.div`
  position: fixed;

  top: 50px;
  bottom: 0px;
  height: calc(100% - 50px);

  left: -250px;
  overflow: auto;

  border-rigth: 1px solid ${color()};
  background-color: ${color(0)};
  ${props => props.open && `transform: translateX(250px);`};

  will-change: transform;
  transition: all 300ms ease-out;
  box-shadow: inset -10px 0px 20px -10px rgba(0, 0, 0, 0.75);

  max-width: 250px;
  width: 100%;

  z-index: 3000;
`;

const InnerMenu = styled.div`
  padding: 20px;

  color: white;
  fill: white;

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  & > * {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export default React.memo(({ isOpen, onRequestClose, targetRef, ...props }) => {
  const menuRef = useRef(null);
  useOutsideCallback([targetRef, menuRef], onRequestClose);

  return (
    <SlidingMenuWrapper ref={menuRef} open={isOpen} {...props}>
      <InnerMenu>
        <WhenModeIs mode="editor">
          <EditorNav />
          <WhenCurrentProject>
            <Separator />
          </WhenCurrentProject>
          <DetailsEditorNav />
          <WhenCurrentProject>
            <Separator />
          </WhenCurrentProject>
          <SettingsEditorNav />
          <WhenCurrentProject>
            <Separator />
          </WhenCurrentProject>
          <LanguageNav />
          <GlobalNav />
        </WhenModeIs>

        <WhenModeIs mode="viewer">
          <NameViewerNav />
          <ViewerNav />
          <Separator />
          <DetailsViewerNav />
          <Separator />
          <IntegrationViewerNav />
          <LanguageNav />
        </WhenModeIs>

        <WhenModeIs mode="admin">
          <AdminNav />
        </WhenModeIs>
      </InnerMenu>
    </SlidingMenuWrapper>
  );
});

import React from 'react';
import styled from 'styled-components/macro';

import * as Sentry from '@sentry/browser';

import Highlight from './Highlight.jsx';
import { Button } from './Button.jsx';

import { defaultLang } from '../state/ui';

const ErrorWrapper = styled.div`
  margin: auto;
  margin-top: 30%;
  text-align: center;
`;

export default class GlobalError extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          {defaultLang() === 'fr' ? (
            <>
              <p>
                <Highlight>Désolé — une erreur inattendue est survenue.</Highlight>
              </p>
              <p>
                Notre équipe a été notifiée, nous vous serions reconnaissant si vous envoyons un
                rapport.
              </p>

              <Button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
                Envoyer un Rapport
              </Button>
            </>
          ) : (
            <>
              <p>
                <Highlight>We are sorry — something has gone wrong.</Highlight>
              </p>
              <p>Our team has been notified, but we are grateful if you send us a report.</p>

              <Button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
                Write Report
              </Button>
            </>
          )}
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }
}

// Palette: http://paletton.com/#uid=63r0Y0kllllaFw0g0qFqFg0w0aF
import { desaturate, lighten } from 'polished';
import { compose } from 'ramda';

export default {
  palette: {
    primary: [
      'rgb( 39, 87,107)',
      'rgb(110,145,161)',
      'rgb( 70,115,134)',
      'rgb( 16, 61, 80)',
      'rgb(  2, 38, 54)',
    ],
    //eslint-disable-next-line camelcase
    secondary_1: [
      'rgb( 72, 46,116)',
      'rgb(141,121,174)',
      'rgb(103, 79,145)',
      'rgb( 45, 21, 87)',
      'rgb( 25,  6, 58)',
    ],
    //eslint-disable-next-line camelcase
    secondary_2: [
      'rgb(91,127, 42)',
      'rgb(183, 216, 139)',
      'rgb(154,195, 97)',
      'rgb( 76,117, 19)',
      'rgb( 45, 78,  0)',
    ],
    complement: [
      'rgb(170,117, 57)',
      'rgb(255,215,170)',
      'rgb(212,162,106)',
      'rgb(128, 77, 21)',
      'rgb( 85, 45,  0)',
    ],
  },

  choices: [
    ['#25576C', '#009966', '#025939'],
    ['#3e395e', '#0066CC', '#00238F'],
    ['#b2181b', '#CC6633', '#993300'],
    ['#A99300', '#E6C700', '#5c6000'],
    ['#992C33', '#996666', '#600042'],
  ],
};

export const color = (type = null, shade = 0) => {
  /* eslint-disable no-param-reassign,no-restricted-globals */
  if (typeof type === 'number' && isFinite(type)) {
    shade = type;
    type = null;
  }
  /* eslint-enable no-param-reassign,no-restricted-globals */
  return ({ color: inputColor, theme }) => {
    if (inputColor) return inputColor;
    if (!theme || !theme.palette) return 'grey';

    return theme.palette[type || theme.defaultColor || 'primary'][shade];
  };
};

const stdColor = color();
export const darkBgColor = compose(
  desaturate(0.3),
  lighten(0.4),
  stdColor
);

export const lightBgColor = compose(
  desaturate(0.3),
  lighten(0.6),
  stdColor
);
export const lighterBgColor = compose(
  desaturate(0.3),
  lighten(0.67),
  stdColor
);

export const darkerBgColor = compose(
  lighten(0.1),
  desaturate(0.3),
  stdColor
);

import { observable, action, autorun, runInAction } from 'mobx';
import { createBrowserHistory as createHistory } from 'history';
import querystring from 'querystring';

import { pathToRegexp } from 'path-to-regexp';

import * as Sentry from '@sentry/browser';

import Project from './project';
import ProjectsLists from './projects-list';

import { clearDateCaches, setLocale, setHolidayRegion } from './dates';
import { queryProjects, createProject, deleteProject } from './backend-interactions';

import { translate } from '../components/Translate.jsx';

const BASE_PATH = pathToRegexp('/:screen?');
const ADMIN_PATH = pathToRegexp('/admin/:screen/:screenInfo?');
const VIEWER_PATH = pathToRegexp('/view/:project/:user/:screen');

const parseLang = lang => {
  if (lang.startsWith('fr')) {
    return 'fr';
  }
  return 'en';
};

export const defaultLang = () => {
  const savedLang = window.localStorage.getItem('uiLang');
  if (savedLang) return savedLang;

  const browserLang = window.navigator.userLanguage || window.navigator.language;
  return parseLang(browserLang);
};

const watchOfflineStatus = ui => {
  window.addEventListener(
    'offline',
    () => {
      // eslint-disable-next-line
      ui.offline = true;
    },
    false
  );
  window.addEventListener(
    'online',
    () => {
      // eslint-disable-next-line
      ui.offline = false;
    },
    false
  );
};

export default () => {
  const projects = new ProjectsLists();

  const history = createHistory({
    basename: '/',
    forceRefresh: false,
  });

  const ui = observable(
    {
      mode: 'none',
      screen: 'settings',
      screenInfo: undefined,
      initialisation: Promise.resolve(),
      ready: false,
      lang: defaultLang(),
      currentPathName: window.location.pathname,

      offline: !navigator.onLine,
      loggedIn: false,
      queryService: false,

      allProjects: projects,

      editorCurrentProject: null,
      viewerCurrentProject: null,

      showLogIn: false,
      menuOpen: false,

      calendarInView: 0,

      changeLang(lang) {
        this.lang = parseLang(lang);
      },

      changeCalendarInView(index) {
        this.calendarInView = index;
      },

      get currentProject() {
        if (this.mode === 'editor') {
          return this.editorCurrentProject;
        }
        return this.viewerCurrentProject;
      },

      get loggedState() {
        if (this.offline) return 'offline';
        if (!this.loggedIn && this.queryService) return 'querying';
        if (this.loggedIn) return 'loggedin';
        return 'loggedout';
      },

      navigateTo(path) {
        this.closeMenu(); // This closes the side menu

        const parsedScreen = BASE_PATH.exec(path);
        if (parsedScreen) {
          const [, screen] = parsedScreen;
          if (screen === 'admin') {
            return this.navigateTo('/admin/projects');
          }

          return this.selectScreen(screen);
        }

        const admin = ADMIN_PATH.exec(path);
        if (admin) {
          const [, screen, screenInfo] = admin;
          return this.selectAdminScreen(screen, screenInfo);
        }

        const view = VIEWER_PATH.exec(path);
        if (view) {
          const [, project, user, screen] = view;
          return this.selectViewerScreen(project, user, screen);
        }

        this.screen = '404';
        return this.initialisation;
      },

      changeScreen(screen, absolute = false) {
        if (this.screen === screen) return;

        this.screen = screen;
        if (screen !== '500') {
          history.push(absolute ? `/${screen}` : `${screen}`);
        }
      },

      selectScreen(screen) {
        this.mode = 'editor';
        let targetScreen = screen;
        if (!targetScreen) {
          targetScreen = window.localStorage.getItem('latestEditorScreen', 'grid');
        }

        if (screen === 'user_params' || screen === 'register') {
          this.changeScreen(screen);
          return this.initialisation;
        }

        if (this.loggedState === 'loggedout') {
          this.changeScreen('login');
          return this.initialisation;
        }

        if (!this.editorCurrentProject) {
          this.initialisation = this.initialisation.then(() => {
            const latestProject = window.localStorage.getItem('latestProject', '');
            if (latestProject && projects.has(latestProject)) {
              this.selectProject(latestProject);
            } else {
              targetScreen = 'settings';
            }
          });
        }

        if (this.screen === targetScreen) return this.initialisation;

        window.localStorage.setItem('latestEditorScreen', targetScreen);

        return this.initialisation.then(() => {
          this.changeScreen(targetScreen, true);
        });
      },

      selectAdminScreen(screen, screenInfo) {
        this.mode = 'admin';

        if (this.screen === screen && this.screenInfo === screenInfo) return this.initialisation;
        this.screen = screen;
        this.screenInfo = screenInfo;
        history.push(`/admin/${screen}${screenInfo ? `/${screenInfo}` : ''}`);
        return this.initialisation;
      },

      selectViewerScreen(project, user, gotoScreen) {
        this.mode = 'viewer';
        let screen = gotoScreen;

        if (this.viewerCurrentProject) {
          return this.initialisation.then(() => {
            this.changeScreen(screen);
          });
        }

        return this.initialisation
          .then(() => {
            this.stopCurrentProject();

            runInAction(() => {
              this.viewerCurrentProject = new Project('', project);
            });
            return this.viewerCurrentProject.load();
          })
          .then(versionName => {
            this.confirmReady();
            if (this.viewerCurrentProject.staffsStore.has(user)) {
              this.viewerCurrentProject.UIStateStore.selectStaff(user);
            } else {
              this.viewerCurrentProject.UIStateStore.enableOverviewMode();
            }

            const versionKey = `lastVersion${project}`;
            const lastVersion = window.localStorage.getItem(versionKey);

            if (!lastVersion) {
              window.localStorage.setItem(versionKey, versionName);
            }

            if (versionName && lastVersion && lastVersion !== versionName) {
              // eslint-disable-next-line no-alert
              alert(translate('SCHEDULE_CHANGED', ui.lang));
              screen = 'history';
              window.localStorage.setItem(versionKey, versionName);
            }
          })
          .catch(() => {
            screen = '404';
          })
          .then(() => {
            this.changeScreen(screen);
          });
      },

      selectProject(projectId) {
        this.stopCurrentProject();
        const project = this.allProjects.get(projectId);

        this.editorCurrentProject = new Project(project ? project.name : '', projectId);
        window.localStorage.setItem('latestProject', projectId);
        this.ready = false;
        this.editorCurrentProject.init().then(() => {
          this.confirmReady();
        });
      },

      resetScreen() {
        if (this.mode === 'admin') {
          return this.selectAdminScreen('projects');
        }

        if (this.mode === 'viewer' && this.viewerCurrentProject.UIStateStore.overviewMode) {
          return this.changeScreen('overview_schedule');
        }

        if (this.mode === 'viewer' && !this.viewerCurrentProject.UIStateStore.overviewMode) {
          return this.changeScreen('individual_calendar');
        }

        if (!this.loggedIn) {
          return this.changeScreen('login');
        }

        if (this.currentProject) {
          return this.changeScreen('grid');
        }

        return this.changeScreen('settings');
      },

      newProject(name) {
        const dbName = this.allProjects.new(name);
        return createProject(dbName, name, this);
      },

      deleteProject(dbName) {
        if (this.editorCurrentProject && this.editorCurrentProject.id === dbName) {
          this.stopCurrentProject();
          window.localStorage.setItem('latestProject', '');
        }

        this.allProjects.delete(dbName);
        if (this.loggedIn) {
          deleteProject(dbName, this);
        }
      },

      startQuerying() {
        this.queryService = true;
      },

      stopQuerying() {
        this.queryService = false;
      },

      stopCurrentProject() {
        clearDateCaches();
        if (this.editorCurrentProject) {
          this.editorCurrentProject.stop();
          this.editorCurrentProject = null;
        }
        if (this.viewerCurrentProject) {
          this.viewerCurrentProject.stop();
          this.viewerCurrentProject = null;
        }
      },

      logInSuccess() {
        this.queryService = false;
        this.loggedIn = true;
      },

      logInFailure() {
        this.queryService = false;
        this.loggedIn = false;
      },

      toggleMenu() {
        this.menuOpen = !this.menuOpen;
      },

      closeMenu() {
        this.menuOpen = false;
      },

      confirmReady() {
        this.ready = true;
      },
    },
    {
      changeLang: action.bound,
      changeCalendarInView: action.bound,
      navigateTo: action.bound,

      changeScreen: action.bound,
      selectScreen: action.bound,
      selectViewerScreen: action.bound,
      resetScreen: action.bound,

      stopCurrentProject: action.bound,

      selectProject: action.bound,
      newProject: action.bound,
      deleteProject: action.bound,
      startQuerying: action.bound,
      stopQuerying: action.bound,
      logInSuccess: action.bound,
      logInFailure: action.bound,

      confirmReady: action.bound,

      toggleMenu: action.bound,
      closeMenu: action.bound,
    }
  );

  watchOfflineStatus(ui);

  history.listen(location => {
    ui.navigateTo(location.pathname);
    ui.currentPathName = location.pathname;
  });

  const qs = querystring.parse(history.location.search.replace(/^\?/, ''));
  queryProjects(ui, qs.token)
    .then(() => ui.navigateTo(history.location.pathname))
    .catch(error => {
      Sentry.captureException(error);
      ui.changeScreen('500');
    })
    .then(() => {
      Sentry.addBreadcrumb({ message: 'ui ready' });
      ui.confirmReady();
    });

  autorun(() => {
    setLocale(ui.lang);

    Sentry.addBreadcrumb({
      message: `setting lang:"${ui.lang}"`,
    });

    window.localStorage.setItem('uiLang', ui.lang);
  });

  autorun(() => {
    if (!ui.currentProject) return;
    if (!ui.currentProject.infoStore.holidays) return;

    const { region, country } = ui.currentProject.infoStore.holidays;

    Sentry.addBreadcrumb({
      message: `setting holiday region country:"${country}" region:"${region}"`,
    });

    setHolidayRegion(country, region);
  });

  return ui;
};

import React from 'react';

import Bowser from 'bowser';

import Translate from './Translate.jsx';
import { LinkButton, Button, ButtonBar } from '../ui-base/Button.jsx';
import Modal from '../ui-base/Modal.jsx';

const STORE_NAME = 'oldBrowserLastPrompted';
const ONE_WEEK = 3600 * 24 * 7 * 1000;

const browser = Bowser.getParser(window.navigator.userAgent);

export default class BrowserModal extends React.Component {
  constructor(props) {
    super(props);

    this.oldBrowser = !browser.satisfies({
      ie: '>11',
      edge: '>=13',
      firefox: '>=29',
      chrome: '>=29',
      Safari: '>=11',
      opera: '>=19',
      ...props.versions,
    });

    const lastPrompted = parseInt(localStorage.getItem(STORE_NAME) || '0', 10);

    this.state = {
      open: this.oldBrowser && Date.now() - lastPrompted > ONE_WEEK,
    };

    this.ignore = this.ignore.bind(this);
  }

  ignore() {
    this.setState({ open: false });
    localStorage.setItem(STORE_NAME, Date.now().toString());
  }

  render() {
    return (
      <Modal isOpen={this.state.open}>
        <h3>
          <Translate>You use an old browser</Translate>
        </h3>
        <p>
          <Translate>This website has been build for more recent browser.</Translate>
        </p>
        <p>
          <Translate>
            You can try using Horair.es, but you will have a better experience with a more recent
            browser.
          </Translate>
        </p>
        <ButtonBar>
          <Button onClick={this.ignore}>
            <Translate>Ignore</Translate>
          </Button>
          {browser.getPlatformType() !== 'mobile' && (
            <LinkButton href="http://outdatedbrowser.com">
              <Translate>More Info</Translate>
            </LinkButton>
          )}
        </ButtonBar>
      </Modal>
    );
  }
}
